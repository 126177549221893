import Vue from 'vue';
import { env } from '../environment.js';
import { UserService } from './user-service.js';

const apiUrl = `${env.url}/products`;

const getProductsForUser = async function() {
	const response = await Vue.axios.get(`${apiUrl}/for-me`, {
		headers: {
			Authorization: `Bearer ${UserService.getUser().token}`,
		},
	});

	return response.data;
};

export const ProductsService = {
	getProductsForUser,
};
