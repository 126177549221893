<template>
	<div class="container">
		<div class="home">
			<pdf
				ref="pdfComponent"
				:src="pdfLoadingTask"
				:page="currentPage"
				@loaded="documentLoaded"
				@page-loaded="pageLoaded"
				style="grid-area: page; z-index: -1"
			></pdf>
			<div ref="signatures" style="grid-area: page; position: relative"></div>
		</div>
		<div class="actions">
			<vs-button flat color="primary" icon="keyboard_arrow_left" @click="loadPreviousPage" :disabled="currentPage <= 1"></vs-button>
			<span>{{ currentPage }} | {{ numPages }}</span>
			<vs-button
				flat
				color="primary"
				icon="keyboard_arrow_right"
				@click="loadNextPage"
				:disabled="currentPage >= numPages"
			></vs-button>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import pdf from 'vue-pdf';
import DigitalSignature from './DigitalSignature';

export default {
	name: 'PdfSignable',
	props: ['url', 'useHandSignature', 'digitalSignature'],
	components: {
		pdf,
	},
	data: function() {
		return {
			signaturePad: {},
			context: {},
			clickX: [],
			clickY: [],
			clickDrag: [],
			paint: false,
			signatures: [],
			currentSignatures: {},
			pdfLoadingTask: {},
			numPages: 0,
			currentPage: 0,
		};
	},
	watch: {
		url: function(newVal) {
			this.pdfLoadingTask = pdf.createLoadingTask(newVal);
			this.pdfLoadingTask.promise.then((pdf) => {
				this.numPages = pdf.numPages;
				this.currentPage = 1;
			});
		},
	},
	created: function() {
		this.pdfLoadingTask = pdf.createLoadingTask(this.url);
	},
	mounted() {
		this.pdfLoadingTask.promise.then(
			(pdf) => {
				this.numPages = pdf.numPages;
				this.currentPage = 1;
			},
			(e) => {
				this.$emit('onDocumentLoadError', e);
			}
		);
	},
	methods: {
		computeRelative(x, y) {
			const pageHeight = this.$refs.signatures.clientHeight;
			const pageWidth = this.$refs.signatures.clientWidth;

			return {
				relativeX: (x / pageWidth) * 100,
				relativeY: (y / pageHeight) * 100,
			};
		},
		computeAbsolute(percentageX, percentageY) {
			const pageHeight = this.$refs.signatures.clientHeight;
			const pageWidth = this.$refs.signatures.clientWidth;
			return {
				absoluteX: (percentageX * pageWidth) / 100,
				absoluteY: (percentageY * pageHeight) / 100,
			};
		},
		loadPreviousPage: function() {
			this.currentPage -= 1;
		},
		loadNextPage: function() {
			this.currentPage += 1;
		},
		documentLoaded: function() {
			this.signatures = [];
			this.currentSignatures = {};
		},
		pageLoaded: function(page) {
			this.signaturePad.innerHTML = '';

			this.currentSignatures = this.signatures.find((item) => item.page === page);

			if (!this.currentSignatures) {
				this.currentSignatures = { page: page, signatures: [] };
				this.signatures.push(this.currentSignatures);
			} else {
				for (let i = 0; i < this.currentSignatures.signatures.length; i++) {
					const x = this.currentSignatures.signatures[i].x;
					const y = this.currentSignatures.signatures[i].y;
					this.addDigitalSignature(x, y, this.currentSignatures.signatures[i]);
				}
			}

			this.signaturePad = this.$refs.signatures;

			if (this.$props.useHandSignature) {
				this.context = this.signaturePad.getContext('2d');
				this.context.strokeStyle = '#ff0000';
				this.context.lineJoin = 'round';
				this.context.lineWidth = 5;
			} else if (this.$props.digitalSignature) {
				this.signaturePad.removeEventListener('pointerup', this.mouseUpHandler);
				this.signaturePad.addEventListener('pointerup', this.mouseUpHandler);
			}
		},
		mouseUpHandler: function(e) {
			if (e.target !== this.$refs.signatures) return;
			const rect = this.$refs.signatures.getBoundingClientRect();
			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;
			const relative = this.computeRelative(x, y);
			const signature = {
				id: new Date().getTime(),
				x: x,
				y: y,
				relativeX: relative.relativeX,
				relativeY: relative.relativeY,
				signature: {
					...this.$props.digitalSignature,
					date: new Date(),
				},
			};
			this.currentSignatures.signatures.push(signature);
			this.addDigitalSignature(x, y, signature);
			this.$emit('signaturesChanged', this.signatures);
		},
		addDigitalSignature: function(x, y, signature) {
			const signatureComponent = Vue.extend(DigitalSignature);
			const instance = new signatureComponent({
				propsData: {
					data: { x: x, y: y, signature: signature.signature, id: signature.id },
				},
			});
			instance.$on('onDelete', () => {
				const newSignatures = this.currentSignatures.signatures.filter((f) => f.id !== instance.id);
				this.currentSignatures.signatures = [...newSignatures];
				this.$emit('signaturesChanged', this.signatures);
			});
			instance.$mount();
			this.signaturePad.appendChild(instance.$el);
		},
		addClick: function(x, y, dragging) {
			this.clickX.push(x);
			this.clickY.push(y);
			this.clickDrag.push(dragging);
		},

		redraw: function() {
			// Clears the signaturePad
			this.context.clearRect(0, 0, this.context.signaturePad.width, this.context.signaturePad.height);

			for (var i = 0; i < this.clickX.length; i += 1) {
				if (!this.clickDrag[i] && i == 0) {
					this.context.beginPath();
					this.context.moveTo(this.clickX[i], this.clickY[i]);
					this.context.stroke();
				} else if (!this.clickDrag[i] && i > 0) {
					this.context.closePath();

					this.context.beginPath();
					this.context.moveTo(this.clickX[i], this.clickY[i]);
					this.context.stroke();
				} else {
					this.context.lineTo(this.clickX[i], this.clickY[i]);
					this.context.stroke();
				}
			}
		},

		drawNew: function() {
			var i = this.clickX.length - 1;
			if (!this.clickDrag[i]) {
				if (this.clickX.length == 0) {
					this.context.beginPath();
					this.context.moveTo(this.clickX[i], this.clickY[i]);
					this.context.stroke();
				} else {
					this.context.closePath();

					this.context.beginPath();
					this.context.moveTo(this.clickX[i], this.clickY[i]);
					this.context.stroke();
				}
			} else {
				this.context.lineTo(this.clickX[i], this.clickY[i]);
				this.context.stroke();
			}
		},
		mouseDownEventHandler: function(e) {
			this.paint = true;
			const rect = e.target.getBoundingClientRect();
			var x = e.clientX - rect.left;
			var y = e.clientY - rect.top;
			if (this.paint) {
				this.addClick(x, y, false);
				this.drawNew();
			}
		},
		mouseUpEventHandler: function() {
			this.context.closePath();
			this.paint = false;
		},
		mouseMoveEventHandler: function(e) {
			const rect = e.target.getBoundingClientRect();
			var x = e.clientX - rect.left;
			var y = e.clientY - rect.top;
			if (this.paint) {
				this.addClick(x, y, true);
				this.drawNew();
			}
		},
		setUpHandler: function(/* isMouseandNotTouch, detectEvent */) {
			this.signaturePad.addEventListener('pointerup', this.mouseUpEventHandler);
			this.signaturePad.addEventListener('pointermove', this.mouseMoveEventHandler);
			this.signaturePad.addEventListener('pointerdown', this.mouseDownEventHandler);
		},
	},
};
</script>
<style lang="scss">
:host {
	height: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.actions {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding: 1rem 0;
		gap: 2rem;
	}
}
.home {
	display: grid;
	grid-template-areas: 'page';
}
</style>
