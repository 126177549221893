import Vue from 'vue';
import { env } from '../environment.js';
import { UserService } from './user-service';

const apiUrl = `${env.url}/orders`;

const placeOrder = async function(product, paymentMethod, allowBiometricValidation, invoiceAddress) {
	const result = await Vue.axios.post(
		`${apiUrl}/client`,
		{
			invoiceAddress: invoiceAddress,
			orderItems: [
				{
					quantity: 1,
					product: product,
				},
			],
			paymentMethod: paymentMethod,
			agreedToBiometricValidation: allowBiometricValidation,
		},
		{
			headers: {
				Authorization: `Bearer ${UserService.getUser().token}`,
			},
		}
	);

	return result.data;
};

const confirmOrder = async function(orderId, sessionId) {
	const result = await Vue.axios.put(`${apiUrl}/${orderId}/client`, null, {
		headers: {
			Authorization: `Bearer ${UserService.getUser().token}`,
			'X-SessionId': sessionId,
		},
	});
	return result.data;
};

export const OrdersService = {
	placeOrder,
	confirmOrder,
};
