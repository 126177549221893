<template>
	<div class="content">
		<div class="left"></div>
		<div class="right"></div>
		<div class="image"><img src="../assets/main_page_img.svg" /></div>
		<div class="login-container" v-if="loginScreen">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('login.title') }}</h2>
			</div>
			<div class="login-form">
				<vs-input color="dark" :label-placeholder="i18n.getLocalised('login.email')" v-model="username" type="email" />
				<vs-input color="dark" :label-placeholder="i18n.getLocalised('login.password')" v-model="password" type="password" />
			</div>
			<div class="errors" v-if="loginError">
				<span class="error">{{ i18n.getLocalised('login.error') }}</span>
			</div>
			<div class="actions">
				<vs-button color="primary" type="flat" @click="login()" :disabled="!allowLogin">{{
					i18n.getLocalised('login.title')
				}}</vs-button>
			</div>
		</div>
		<div class="otp-container" v-if="!loginScreen">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('login.otpTitle') }}</h2>
			</div>
			<div class="login-form">
				<vs-input color="dark" :label-placeholder="i18n.getLocalised('login.otp')" v-model="otpCode" type="password" />
			</div>
			<div class="actions">
				<vs-button color="primary" type="border" @click="validateOtp()">{{ i18n.getLocalised('button.confirm') }}</vs-button>
				<vs-button color="dark" type="flat" @click="login()" :disabled="!allowResend"
					>{{ i18n.getLocalised('button.resend') }} {{ allowResend ? '' : '(' + resendOtpTimer + ')' }}</vs-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { UserService } from '../services/user-service.js';
import { EventBus } from '../services/events-service.js';
import { events } from '../events';

export default {
	data: function() {
		return {
			username: '',
			password: '',
			otpCode: '',
			sessionId: '',
			redirectUrl: '',
			loginScreen: true,
			laoding: false,
			loginError: false,
			resendOtpTimer: 0,
			allowResend: false,
			allowLogin: true,
		};
	},
	watch: {
		resendOtpTimer: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.resendOtpTimer--;
					}, 1000);
				} else {
					this.allowResend = true;
				}
			},
		},
	},
	methods: {
		login: async function() {
			this.loading = true;
			this.allowLogin = false;
			try {
				const loginResult = await UserService.login(this.username, this.password);

				this.loginError = false;

				const response = loginResult.data;
				this.sessionId = response.sessionId;
				this.redirectUrl = response.redirectUrl;

				this.loginScreen = false;
				this.resendOtpTimer = 60;
				this.allowResend = false;
				EventBus.$emit(events.otpRequested);
			} catch {
				this.loginError = true;
				this.allowLogin = true;
			}
		},
		validateOtp: async function() {
			this.loading = true;
			try {
				const verifyOtpResponse = await UserService.verifyOtp(this.sessionId, this.otpCode);
				var verifyOtp = await verifyOtpResponse.data;
				sessionStorage.setItem('id_token', verifyOtp.id_token);
				EventBus.$emit(events.userLoggedIn);
				this.$router.push(this.redirectUrl || { name: 'Home' });
			} catch {
				this.loginError = true;
				this.loginScreen = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.content {
	width: 100vw;
	height: calc(100vh - 50px);
	display: grid;
	grid-template-rows: 2fr 2fr 2fr 2fr 2fr;
	grid-template-columns: repeat(8, 1fr);
	gap: 2rem;
}

.left {
	grid-row: 1/-1;
	grid-column: 1 / span 4;
}
.right {
	grid-row: 1/-1;
	grid-column: 6/-1;
	background-color: #75b8c8;
	border-left: 1px solid #76b9d5;
}
.image {
	grid-row: 2 / span 2;
	grid-column: 5 / span 3;

	> img {
		transform: rotateY(180deg);
	}
}

.login-container,
.otp-container {
	grid-row: 2;
	grid-column: 2 / span 2;
	display: flex;
	flex-direction: column;
	padding: 30px 15px;
	background-color: #fff;
	// box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.5);
	justify-content: space-between;

	.header {
		flex: 0;
		margin-bottom: 0.7rem;
	}

	.login-form {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: center;

		.vs-con-input-label {
			width: 100%;
		}
	}

	.errors {
		flex: 0;
		padding: 0.5rem 0;
		font-size: 0.7rem;
		.error {
			color: #ff2200;
		}
	}

	.actions {
		flex: 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 1rem 0;
	}
}

@media screen and (max-width: 800px) {
	.content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		height: 100vh;
		width: 100vw;
	}
	.login-container,
	.otp-container {
		grid-column: 1;
		grid-row: 1;
	}
	.left {
		grid-column: 1;
		grid-row: 1;
	}
	.right {
		grid-column: 1;
		grid-row: 2;
	}
	.image {
		grid-column: 1;
		grid-row: 2;
		> img {
			object-fit: contain;
		}
	}
}
</style>
