import Vue from 'vue';
import VueRouter from 'vue-router';

import axios from 'axios';
import VueAxios from 'vue-axios';

// import Home from '../views/Home.vue';
import SignDocument from '../views/SignDocument.vue';
import ConfirmAccount from '../views/ConfirmAccount.vue';
import EndConfirmAccount from '../views/EndConfirmAccount.vue';
import ProcessError from '../views/ProcessError.vue';
import SetCertificatePass from '../views/SetCertificatePass.vue';
import VideoValidation from '../views/VideoValidation.vue';
import PurchaseCertificate from '../views/PurchaseCertificate.vue';
import RegistrationForm from '../views/RegistrationForm.vue';

import Profile from '../views/Profile.vue';
import Login from '../views/Login.vue';

import { utils } from '../utils.js';

Vue.use(VueAxios, axios);

Vue.use(VueRouter);

const authGuard = (to, from, next) => {
	if (to.name !== 'Login') {
		const token = sessionStorage.getItem('id_token');
		if (token) {
			const parsedToken = utils.parseJwt(token);
			const expiration = new Date(0).setUTCSeconds(parsedToken.exp);
			const notBefore = new Date(0).setUTCSeconds(parsedToken.nbf);
			const now = new Date().getTime() + new Date().getTimezoneOffset() + 5000;
			const isAuthenticated = +expiration > +now && +notBefore <= +now;
			if (!isAuthenticated) {
				sessionStorage.removeItem('id_token');
				router.push({ name: 'Login' });
			} else {
				next();
			}
		} else {
			router.push({ name: 'Login' });
		}
	} else next();
};

const routes = [
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: Home,
	// 	beforeEnter: authGuard,
	// },
	{
		path: '/sign-document',
		name: 'SignDocument',
		component: SignDocument,
		beforeEnter: authGuard,
	},
	{
		path: '/purchase',
		name: 'PurchaseCertificate',
		component: PurchaseCertificate,
		beforeEnter: authGuard,
	},
	{
		path: '/my-profile',
		name: 'Profile',
		component: Profile,
		beforeEnter: authGuard,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '',
		alias: '/',
		redirect: '/sign-document',
		name: 'Home',
	},
	{
		path: '/confirm-account',
		name: 'ConfirmAccount',
		component: ConfirmAccount,
	},
	{
		path: '/set-certificate',
		name: 'SetupCertificate',
		component: SetCertificatePass,
	},
	{
		path: '/video-validation',
		name: 'VideoValidation',
		component: VideoValidation,
	},
	{
		path: '/end-confirm-account',
		name: 'EndConfirmAccount',
		component: EndConfirmAccount,
	},
	{
		path: '/register',
		name: 'RegisterSignatory',
		component: RegistrationForm,
	},
	{
		path: '/process-error',
		name: 'ProcessError',
		component: ProcessError,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
