import Vue from 'vue';
import { env } from '../environment.js';
import { utils } from '../utils';

const apiUrl = `${env.url}/accounts`;
const apiClientsUrl = `${env.url}/clients`;

const login = function(username, password) {
	return Vue.axios.post(
		`${apiUrl}/login`,
		{ email: username, password: password },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
};

const verifyOtp = function(sessionId, otpCode) {
	return Vue.axios.post(
		`${apiUrl}/validate-token`,
		{ sessionId: sessionId, otpCode: otpCode },
		{
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
};

const registerAccount = function(account) {
	return Vue.axios.post(`${apiUrl}`, account, {
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

const logout = function() {
	const user = getUser();
	if (user) {
		sessionStorage.removeItem('id_token');
		//fire and forget
		Vue.axios.delete(`${apiUrl}/logout`, {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		});
	}
	return Promise.resolve();
};

const getUser = function() {
	const token = sessionStorage.getItem('id_token');
	if (token) {
		const parsedToken = utils.parseJwt(token);
		return {
			id: parsedToken.id,
			firstName: parsedToken.given_name,
			lastName: parsedToken.family_name,
			email: parsedToken.email,
			roles: parsedToken.role.split(','),
			token: token,
		};
	}
	return null;
};

const getUserProfile = async function() {
	const token = sessionStorage.getItem('id_token');
	return await Vue.axios.get(`${apiClientsUrl}/me`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getOrderDocument = async function(orderId, documentId) {
	const token = sessionStorage.getItem('id_token');
	return await Vue.axios.get(`${apiClientsUrl}/orders/${orderId}/documents/${documentId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	});
};

const getUserHistory = async function() {
	const token = sessionStorage.getItem('id_token');
	return await Vue.axios.get(`${apiClientsUrl}/my-history`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const confirmEmail = async function(token) {
	try {
		await Vue.axios.post(`${apiUrl}/confirm-account?token=${token}`);
		return true;
	} catch {
		return Promise.reject();
	}
};

const getConfirmPhone = async function(token) {
	try {
		const response = await Vue.axios.get(`${apiUrl}/confirm-account/phone-number?token=${token}`);
		return response.data;
	} catch (err) {
		throw Error(err);
	}
};

const confirmPhone = async function(confirmAccountDto) {
	try {
		await Vue.axios.post(`${apiUrl}/confirm-account/phone-number`, confirmAccountDto);
		return true;
	} catch (err) {
		return Promise.reject(err);
	}
};

const getVideoSessionAvailability = async function(clientId, token, sessionId) {
	try {
		await Vue.axios.get(`${apiUrl}/${clientId}/video-session?sh=${sessionId}&t=${token}`);
		return { canStart: true };
	} catch (err) {
		if (err.response.status === 412) {
			return { canStart: false, error: err.response.data.code };
		} else if (err.response.status === 400) {
			return { canStart: false, error: err.response.data.code };
		} else {
			throw err;
		}
	}
};

const createVideoSession = async function(clientId, token, sessionId) {
	var response = await Vue.axios.post(`${apiUrl}/${clientId}/video-session?sh=${sessionId}&t=${token}`);
	return response.data;
};

const getCertificateRequest = async function(token) {
	try {
		await Vue.axios.get(`${apiUrl}/certificate-request?token=${token}`);
		return true;
	} catch {
		return Promise.reject();
	}
};

const getOtpForCertificateRequest = async function(token) {
	try {
		const response = await Vue.axios.post(`${apiUrl}/certificate-request?token=${token}`);
		return response.data;
	} catch (err) {
		throw Error(err);
	}
};

const confirmCertificateRequest = async function(confirmAccountDto) {
	try {
		await Vue.axios.put(`${apiUrl}/certificate-request`, confirmAccountDto);
		return true;
	} catch (err) {
		return Promise.reject(err);
	}
};

const getClientDocuments = async function() {
	const token = sessionStorage.getItem('id_token');
	return await Vue.axios.get(`${apiClientsUrl}/my-documents`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const getClientDocument = async function(documentId) {
	const token = sessionStorage.getItem('id_token');
	return await Vue.axios.get(`${apiClientsUrl}/my-documents/${documentId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	});
};

const getMrzInformation = async function(document, fileName) {
	const token = sessionStorage.getItem('id_token');
	const formData = new FormData();
	formData.append('idDocument', document, fileName);
	return await Vue.axios.post(`${apiClientsUrl}/me/mrz`, formData, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const updatePersonalInformation = async function(idDocument, fileName, personalInfo) {
	const token = sessionStorage.getItem('id_token');
	const formData = new FormData();
	formData.append('idDocument', idDocument, fileName);
	formData.append('firstName', personalInfo.firstName);
	formData.append('lastName', personalInfo.lastName);
	formData.append('idCardSeries', personalInfo.documentSeries);
	formData.append('idCardNumber', personalInfo.documentNumber);
	formData.append('cnp', personalInfo.cnp);
	formData.append('idCardIssueDate', personalInfo.issueDate);
	formData.append('idCardExpiryDate', personalInfo.expiryDate);

	return await Vue.axios.put(`${apiClientsUrl}/me`, formData, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const UserService = {
	login,
	verifyOtp,
	registerAccount,
	getUser,
	logout,
	confirmEmail,
	getConfirmPhone,
	confirmPhone,
	getVideoSessionAvailability,
	createVideoSession,
	getCertificateRequest,
	confirmCertificateRequest,
	getOtpForCertificateRequest,
	getUserProfile,
	getUserHistory,
	getOrderDocument,
	getClientDocuments,
	getClientDocument,
	getMrzInformation,
	updatePersonalInformation,
};
