<template>
	<div class="content" >
		<div class="header">
			<img class="logo" src="../assets/logo@2x.png" />
		</div>
		<div class="left"></div>
		<div class="right"></div>
		<div class="container" v-if="!!formInfo">
			<vs-card>
				<div slot="header">
					<p>{{ i18n.getLocalised('registrationForm.title') }} {{ formInfo.companyName }}</p>
				</div>
				<div class="form"
					:class="(selectedProduct && selectedProduct.productType.productCategory === 2) ? 'columns-4' : 'columns-3'"
					v-if="!!formInfo && !showEndScreen">
					<div class="selectProduct">
						<vs-select :label="i18n.getLocalised('registrationForm.fields.product')" v-model="selectedProduct" autocomplete>
							<vs-select-item :key="index" :value="item.product"
								:text="item.product.name + '(' + item.product.code + ')'"
								v-for="item, index in formInfo.products" />
						</vs-select>
					</div>
					<div class="uploadIdCard">
						<vs-button v-if="!idCard" type="gradient" color="success" @click="() => { showMrz = true; }">
							{{ i18n.getLocalised('registrationForm.fields.uploadIdCard') }}</vs-button>
						<div v-if="idCard">
							<vs-chip color="success">
								<vs-icon icon="check_circle"></vs-icon>
								{{ i18n.getLocalised('registrationForm.fields.uploadedIdCard') }}
							</vs-chip>
						</div>
					</div>
					<div class="personalInformation" v-if="idCard">
						<h3>{{ i18n.getLocalised('registrationForm.fields.personalInformation') }}</h3>
						<div class="documentType">
							<vs-switch v-model="isIdCard" :color="documentType === 'I' ? 'success' : 'primary'">
								<span slot="on">{{ i18n.getLocalised('registrationForm.fields.documentType.idCard')
								}}</span>
								<span slot="off">{{ i18n.getLocalised('registrationForm.fields.documentType.passport')
								}}</span>
							</vs-switch>
						</div>
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.lastName')"
							v-model="personalInformation.lastName" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.firstName')"
							v-model="personalInformation.firstName" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.cnp')"
							@blur="validateCnp"
							:danger="!isValidCnp"
							:danger-text="i18n.getLocalised('registrationForm.validation.invalidCnp')"
							v-if="documentType === 'I'"
							v-model="personalInformation.cnp" />
						<div class="idCardNumberField">
							<vs-input class="idCardSeries"
								:label-placeholder="i18n.getLocalised('registrationForm.fields.idCardSeries')"
								v-if="documentType === 'I'" v-model="personalInformation.idCardSeries" />
							<vs-input class="idCardNumber"
								:label-placeholder="i18n.getLocalised('registrationForm.fields.idCardNumber')"
								v-model="personalInformation.idCardNumber" />
						</div>
						<div class="datepicker" :class="{ 'hasValue': personalInformation.idCardIssueDate != null }">
							<date-picker v-model="personalInformation.idCardIssueDate" />
							<span>{{ i18n.getLocalised('registrationForm.fields.issueDate') }}</span>
						</div>
						<div class="datepicker" :class="{ 'hasValue': personalInformation.idCardExpiryDate != null }">
							<date-picker v-model="personalInformation.idCardExpiryDate" />
							<span>{{ i18n.getLocalised('registrationForm.fields.expiryDate') }}</span>
						</div>
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.issuedBy')"
							v-model="personalInformation.issuedBy" />
					</div>
					<div class="contactInformation" v-if="idCard">
						<h3>{{ i18n.getLocalised('registrationForm.fields.contactInformation') }}</h3>
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.email')"
							:danger="!isValidEmail"
							:danger-text="i18n.getLocalised('registrationForm.validation.invalidEmail')"
							@blur="validateEmail"
							v-model="contactInformation.email" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.phoneNumber')"
							:danger="!isValidPhoneNumber"
							:danger-text="i18n.getLocalised('registrationForm.validation.invalidPhoneNumber')"
							@blur="validatePhoneNumber"
							v-model="contactInformation.phoneNumber" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.institution')"
							v-model="contactInformation.institution" />
					</div>
					<div class="address" v-if="idCard">
						<h3>{{ i18n.getLocalised('registrationForm.fields.address') }}</h3>
						<vs-select :label="i18n.getLocalised('registrationForm.fields.country')" v-model="address.country" autocomplete>
							<vs-select-item :key="index" :value="item.code" :text="item.name"
								v-for="item, index in countries" />
						</vs-select>
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.county')"
							v-model="address.county" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.city')"
							v-model="address.city" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.street')"
							v-model="address.street" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.streetNumber')"
							v-model="address.streetNumber" />
						<div class="address-building">
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.building')"
								v-model="address.building" />
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.buildingNumber')"
								v-model="address.buildingNumber" />
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.apartment')"
								v-model="address.apartment" />
						</div>
					</div>
					<div class="delivery" v-if="(idCard && selectedProduct && selectedProduct.productType.productCategory === 2)">
						<h3>{{ i18n.getLocalised('registrationForm.fields.deliveryAddress') }}</h3>
						<vs-switch v-model="sameAddress" color="success">
							<span slot="on">{{ i18n.getLocalised('registrationForm.fields.deliveryAddress.asAddress')
							}}</span>
							<span slot="off">{{ i18n.getLocalised('registrationForm.fields.deliveryAddress.notAsAddress')
							}}</span>
						</vs-switch>
						<vs-select :label="i18n.getLocalised('registrationForm.fields.country')" v-model="delivery.country"
							:disabled="sameAddress">
							<vs-select-item :key="index" :value="item.code" :text="item.name"
								v-for="item, index in countries" />
						</vs-select>
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.county')"
							v-model="delivery.county" :disabled="sameAddress" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.city')"
							v-model="delivery.city" :disabled="sameAddress" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.street')"
							v-model="delivery.street" :disabled="sameAddress" />
						<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.streetNumber')"
							v-model="delivery.streetNumber" :disabled="sameAddress" />
						<div class="address-building">
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.building')"
								v-model="delivery.building" :disabled="sameAddress" />
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.buildingNumber')"
								v-model="delivery.buildingNumber" :disabled="sameAddress" />
							<vs-input :label-placeholder="i18n.getLocalised('registrationForm.fields.apartment')"
								v-model="delivery.apartment" :disabled="sameAddress" />
						</div>
					</div>
					<div class="settings" v-if="(idCard && selectedProduct && selectedProduct.productType.productCategory === 2)">
						<h3>{{ i18n.getLocalised('registrationForm.fields.settings') }}</h3>
						<vs-input type="password" :label-placeholder="i18n.getLocalised('registrationForm.fields.password')"
							:danger="!isValidPassword"
							:danger-text="i18n.getLocalised('registrationForm.validation.invalidPassword')"
							@blur="validatePassword"
							v-model="settings.password" />
						<vs-input type="password"
							:label-placeholder="i18n.getLocalised('registrationForm.fields.confirmPassword')"
							:danger="!isValidConfirmPassword"
							:danger-text="i18n.getLocalised('registrationForm.validation.passwordsMustMatch')"
							@blur="validateConfirmPassword"
							v-model="settings.confirmPassword" />
					</div>
					<!-- <vs-tabs>
						<vs-tab :label="i18n.getLocalised('registrationForm.selectProduct.title')">
						</vs-tab>
						<vs-tab :label="i18n.getLocalised('registrationForm.uploadIdCard.title')">
						</vs-tab>
						<vs-tab :label="i18n.getLocalised('registrationForm.personalInformation.title')">
						</vs-tab>
						<vs-tab :label="i18n.getLocalised('registrationForm.contactInformation.title')">
						</vs-tab>
						<vs-tab :label="i18n.getLocalised('registrationForm.address.title')">
						</vs-tab>
						<vs-tab v-if="selectedProduct?.product?.productType.productCategory === 2" :label="i18n.getLocalised('registrationForm.deliveryInformation.title')">
						</vs-tab>
						<vs-tab v-if="selectedProduct?.product?.productType.productCategory === 2" :label="i18n.getLocalised('registrationForm.deviceSettings.title')">
						</vs-tab>
					</vs-tabs> -->
					<div class="actions">
						<div class="agreements">
							<div class="checkbox-click">
								<vs-checkbox v-model="agreedToTerms" @click="showTerms = true" color="success">
									<span>{{ i18n.getLocalised('registrationForm.agreements.terms') }}</span>
								</vs-checkbox>
								<div class="invisible" @click="showTermsPopup"></div>
								<!-- This is a hack to make the checkbox clickable -->
							</div>
							<div class="checkbox-click">
								<vs-checkbox v-model="agreedToGdpr" @click="showGdpr = true" color="success">
									<span>{{ i18n.getLocalised('registrationForm.agreements.gdpr') }}</span>
								</vs-checkbox>
								<div class="invisible" @click="showGdprPopup"></div>
								<!-- This is a hack to make the checkbox clickable -->
							</div>
							<div class="checkbox-click">
								<vs-checkbox v-model="agreedToPrivacyPolicy" @click="showPrivacyPolicy = true"
									color="success">
									<span>{{ i18n.getLocalised('registrationForm.agreements.privacyPolicy') }}</span>
								</vs-checkbox>
								<div class="invisible" @click="showPrivacyPolicyPopup"></div>
								<!-- This is a hack to make the checkbox clickable -->
							</div>
						</div>
						<vs-button type="gradient" color="success" @click="submit">{{ i18n.getLocalised('registrationForm.submit') }}</vs-button>
					</div>
				</div>
				<div class="end-screen" v-if="showEndScreen">
					<p>{{ i18n.getLocalised('registrationForm.endScreen') }}</p>
				</div>
			</vs-card>
		</div>
		<vs-popup :title="i18n.getLocalised('registrationForm.termsAndConditions')" :active.sync="showTerms">
			<div class="prompt">
				<vue-markdown :source="agreements.terms" html linkify></vue-markdown>
				<div class="actions">
					<vs-button type="gradient" color="success" @click="() => { agreedToTerms = true; showTerms = false; }">{{
						i18n.getLocalised('registrationForm.agree') }}</vs-button>
					<vs-button type="gradient" color="danger" @click="() => { agreedToTerms = false; showTerms = false; }">{{
						i18n.getLocalised('registrationForm.disagree') }}</vs-button>
				</div>
			</div>
		</vs-popup>
		<vs-popup :title="i18n.getLocalised('registrationForm.gdpr')" :active.sync="showGdpr">
			<div class="prompt">
				<vue-markdown :source="agreements.gdpr" html linkify></vue-markdown>
				<div class="actions">
					<vs-button type="gradient" color="success" @click="() => { agreedToGdpr = true; showGdpr = false; }">{{
						i18n.getLocalised('registrationForm.agree') }}</vs-button>
					<vs-button type="gradient" color="danger" @click="() => { agreedToGdpr = false; showGdpr = false; }">{{
						i18n.getLocalised('registrationForm.disagree') }}</vs-button>
				</div>
			</div>
		</vs-popup>
		<vs-popup :title="i18n.getLocalised('registrationForm.privacyPolicy')" :active.sync="showPrivacyPolicy">
			<div class="prompt">
				<vue-markdown :source="agreements.privacy" html linkify></vue-markdown>
				<div class="actions">
					<vs-button type="gradient" color="success" @click="() => { agreedToPrivacyPolicy = true; showPrivacyPolicy = false; }">{{
						i18n.getLocalised('registrationForm.agree') }}</vs-button>
					<vs-button type="gradient" color="danger" @click="() => { agreedToPrivacyPolicy = false; showPrivacyPolicy = false; }">{{
						i18n.getLocalised('registrationForm.disagree') }}</vs-button>
				</div>
			</div>
		</vs-popup>
		<div v-if="loaded && showMrz" class="popover">
			<div>
				<span>{{ i18n.getLocalised('registrationForm.uploadIdCardInstructions') }}</span>
			</div>
			<div v-if="isMobile && !isLandscape">
				<span>{{ i18n.getLocalised('registrationForm.rotatePhone') }}</span>
			</div>
			<div v-if="!isMobile || (isMobile && isLandscape)">
				<mrz-component @on-complete="onGetMrz" @on-error="onMrzError"></mrz-component>
			</div>
		</div>
	</div>
</template>

<script>
import { RegistrationFormService } from '../services/registration-form.service';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueMarkdown from 'vue-markdown-v2';

import countries from '../services/countries.datasource';

export default {
	components: { DatePicker, VueMarkdown },
	data: function() {
		return {
			formId: '',
			companyId: '',
			formInfo: null,
			selectedProduct: null,
			idCard: null,
			file: null,
			fileName: '',
			personalInformation: {},
			contactInformation: {},
			address: {
				country: 'RO'
			},
			delivery: {
				country: 'RO'
			},
			settings: {},
			documentType: 'I', //I for ID card, P for passport
			sameAddress: false,
			countries: countries,
			isIdCard: true,
			showEndScreen: false,
			agreedToTerms: false,
			agreedToGdpr: false,
			agreedToPrivacyPolicy: false,
			agreements: {
				terms: '',
				gdpr: '',
				privacyPolicy: '',
			},
			showTerms: false,
			showGdpr: false,
			showPrivacyPolicy: false,
			isValidCnp: true,
			isValidEmail: true,
			isValidPhoneNumber: true,
			isValidPassword: true,
			isValidConfirmPassword: true,
			showMrz: false,
			loaded: false,
			isLandscape: true,
			isMobile: false,
		};
	},
	computed: {
		allowSubmit: function () {
			return this.agreedToGdpr && this.agreedToPrivacyPolicy && this.agreedToTerms && !!this.selectedProduct
				&& !!this.idCard
				&& this.isValidPersonalInformation() && this.isValidContactInformation()
				&& this.isValidAddress() && this.isValidDelivery() && this.isValidSettings();
		}
	},
	watch: {
		sameAddress: function (newVal) {
			if (newVal) {
				this.delivery = this.address;
			} else {
				this.delivery = {
					country: 'RO'
				};
			}
		},
		isIdCard: function (newVal) {
			if (newVal) {
				this.documentType = 'I';
			} else {
				this.documentType = 'P';
			}
		}
	},
	async mounted() {
		this.isMobile = 
			/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(navigator.userAgent) ||
			/\b(Android|Windows Phone|iPad|iPod)\b/i.test(navigator.userAgent);

		this.isLandscape = window.screen.orientation.type.includes('landscape');
		window.addEventListener('orientationchange', () => {
			this.isLandscape = window.screen.orientation.type.includes('landscape');
		});

		this.$vs.loading({ type: 'border' });
		const mrzComponent = document.createElement('script');
		mrzComponent.setAttribute('src', 'https://app.remoteid.ro/cdn/mrz-component.js');
		document.head.appendChild(mrzComponent);

		this.formHash = this.$route.query.fh;
		this.companyId = this.$route.query.cid;
		const response = await RegistrationFormService.getRegistrationForm(this.formHash, this.companyId);
		this.formInfo = response.data;
		console.log(this.formInfo);
		await this.loadAgreements();
		this.loaded = true;
		this.$vs.loading.close();
	},
	methods: {
		async getMrz() {
			if (this.file) {
				this.mrzInfo = (await RegistrationFormService.getMrzInformation(this.companyId, this.formHash, this.file, this.fileName)).data;
				
				if(this.mrzInfo) {
					this.personalInformation.firstName = this.mrzInfo.name;
					this.personalInformation.lastName = this.mrzInfo.surname;
					this.personalInformation.documentType = this.mrzInfo.document_type;
					if(this.mrzInfo.document_type === 'I') {
						this.isIdCard = true;
						const idCardSeries = this.mrzInfo.document_number.substring(0, 2);
						const idCardNumber = this.mrzInfo.document_number.substring(2);
						this.personalInformation.idCardSeries = idCardSeries;
						this.personalInformation.idCardNumber = idCardNumber;
						this.personalInformation.cnp = this.mrzInfo.cnp;
					} else {
						this.isIdCard = false;
						this.personalInformation.idCardNumber = this.mrzInfo.document_number;
						this.personalInformation.cnp = this.mrzInfo.document_number;
					}

					this.personalInformation.idCardExpiryDate = new Date(`20${this.mrzInfo.expiry_date.substring(0, 2)}-${this.mrzInfo.expiry_date.substring(2, 4)}-${this.mrzInfo.expiry_date.substring(4, 6)}`);
				}
			}
		},
		async submit() {
			let hasError = false;
			if (!this.selectedProduct) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.product'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.idCard) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.idCard'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.isValidPersonalInformation()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.invalidPersonalInformation'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.isValidContactInformation()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.invalidContactInformation'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.isValidAddress()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.invalidAddress'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.isValidDelivery()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.invalidDeliveryAddress'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.isValidSettings()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.invalidSettings'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}
			if (!this.hasAgreedToTerms()) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('registrationForm.validation.mustAcceptAgreements'),
					color: 'danger',
					position: 'bottom-center',
				});
				hasError = true;
			}

			if (hasError) return;

			let offset = this.personalInformation.idCardIssueDate.getTimezoneOffset();
			let issueDate = new Date(this.personalInformation.idCardIssueDate.getTime() - (offset * 60 * 1000));
			issueDate = issueDate.toISOString().split('T')[0];
			offset = this.personalInformation.idCardExpiryDate.getTimezoneOffset();
			let expiryDate = new Date(this.personalInformation.idCardExpiryDate.getTime() - (offset * 60 * 1000));
			expiryDate = expiryDate.toISOString().split('T')[0];

			const formData = new FormData();
			formData.append('email', this.contactInformation.email);
			formData.append('phoneNumber', this.contactInformation.phoneNumber);
			formData.append('institution', this.contactInformation.institution);
			formData.append('lastName', this.personalInformation.lastName);
			formData.append('firstName', this.personalInformation.firstName);
			formData.append('cnp', this.personalInformation.cnp);
			this.personalInformation.idCardNumber && formData.append('idCardNumber', this.personalInformation.idCardNumber);
			formData.append('idCardSeries', this.personalInformation.idCardSeries);
			formData.append('idCardIssueDate', issueDate);
			formData.append('idCardExpiryDate', expiryDate);
			formData.append('idCardIssuedBy', this.personalInformation.issuedBy);
			formData.append('address[country]', this.address.country);
			formData.append('address[county]', this.address.county);
			formData.append('address[city]', this.address.city);
			formData.append('address[street]', this.address.street);
			formData.append('address[streetNumber]', this.address.streetNumber);
			this.address.building && formData.append('address[building]', this.address.building);
			this.address.buildingNumber && formData.append('address[buildingNumber]', this.address.buildingNumber);
			this.address.apartment && formData.append('address[apartment]', this.address.apartment);
			if (this.selectedProduct.productType.productCategory === 2) {
				formData.append('delivery[country]', this.delivery.country);
				formData.append('delivery[county]', this.delivery.county);
				formData.append('delivery[city]', this.delivery.city);
				formData.append('delivery[street]', this.delivery.street);
				formData.append('delivery[streetNumber]', this.delivery.streetNumber);
				this.delivery.building && formData.append('delivery[building]', this.delivery.building);
				this.delivery.buildingNumber && formData.append('delivery[buildingNumber]', this.delivery.buildingNumber);
				this.delivery.apartment && formData.append('delivery[apartment]', this.delivery.apartment);
			}
			this.settings.password && formData.append('password', this.settings.password);
			formData.append('productCode', this.selectedProduct.code);
			formData.append('idCard', this.file, this.fileName);
			formData.append('agreedToTermsAndConditions', this.agreedToTerms);
			formData.append('agreedToGdpr', this.agreedToGdpr);
			formData.append('agreedToPrivacyPolicy', this.agreedToPrivacyPolicy);

			this.$vs.loading({ type: 'border' });

			try {
				await RegistrationFormService.submitRegistrationForm(this.formHash, this.companyId, formData);
				this.$vs.loading.close();
				this.$vs.notify({
					title: 'Success',
					text: this.i18n.getLocalised('registrationForm.submitSuccess'),
					color: 'success',
					position: 'bottom-center',
				});
				this.showEndScreen = true;
			} catch (err) {
				let errMessage = 'registrationForm.submitError';
				console.log(err.response, err.response.data);
				if (err.response && err.response.data) {
					errMessage = `registrationForm.submitError.${err.response.data.code}`;
				}
				this.$vs.loading.close();
				this.$vs.notify({
					title: 'Error',
					text: this.i18n.getLocalised(errMessage),
					color: 'danger',
					position: 'bottom-center',
				});
			}
		},
		isValidPersonalInformation() {
			return this.personalInformation.firstName && this.personalInformation.lastName
				&& ((this.personalInformation.documentType === "I" && this.personalInformation.cnp && this.isValidCnp) || this.personalInformation.documentType !== "I")
				&& this.personalInformation.idCardNumber
				&& ((this.personalInformation.documentType === "I" && this.personalInformation.idCardSeries) || this.personalInformation.documentType !== "I") 
				&& this.personalInformation.idCardIssueDate
				&& this.personalInformation.idCardExpiryDate && this.personalInformation.issuedBy;
		},
		isValidAddress() {
			return this.address.country && this.address.county && this.address.city
				&& this.address.street && this.address.streetNumber;
		},
		isValidDelivery() {
			return (!this.selectedProduct || this.selectedProduct.productType.productCategory !== 2)
				|| (this.delivery.country && this.delivery.county && this.delivery.city
					&& this.delivery.street && this.delivery.streetNumber);
		},
		isValidSettings() {
			return (this.selectedProduct && this.selectedProduct.productType.productCategory !== 2) 
				|| ( this.selectedProduct && this.settings.password && this.settings.confirmPassword && this.isValidPassword && this.isValidConfirmPassword);
		},
		isValidContactInformation() {
			return this.contactInformation.email && this.contactInformation.phoneNumber && this.isValidEmail && this.isValidPhoneNumber;
		},
		hasAgreedToTerms() {
			return this.agreedToTerms && this.agreedToGdpr && this.agreedToPrivacyPolicy;
		},
		async fileSelected(e) {
			try {
				this.file = e.target.files[0];
				this.fileName = this.file.name;
				this.$vs.loading({ type: 'border' });
				try {
					await this.getMrz();
				} catch(err){
					console.log(err);
				} finally {
					this.$vs.loading.close();
				}
				const reader = new FileReader();
				reader.readAsDataURL(this.file);
				reader.onload = () => {
					this.idCard = reader.result;
				};
			} catch (e) {
				this.$vs.notify({
					title: 'Invalid',
					text: this.i18n.getLocalised('INVALID_FILE'),
					color: 'danger',
					position: 'bottom-center',
				});
			}
		},
		async loadAgreements() {
			const response = await RegistrationFormService.getAgreements(this.companyId);
			console.log(response);
			this.agreements = response;
		},
		showTermsPopup() {
			if (this.agreedToTerms)
				this.agreedToTerms = false;
			else
				this.showTerms = true;
		},
		showGdprPopup() {
			if (this.agreedToGdpr)
				this.agreedToGdpr = false;
			else
				this.showGdpr = true;
		},
		showPrivacyPolicyPopup() {
			if (this.agreedToPrivacyPolicy)
				this.agreedToPrivacyPolicy = false;
			else
				this.showPrivacyPolicy = true;
		},
		validateCnp() {
			if (this.documentType !== 'I') {
				this.isValidCnp = true;
				return;
			}
			if (this.personalInformation.cnp.length !== 13) {
				this.isValidCnp = false;
			} else {
				let sum = 0;
				let control = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
				for (let i = 0; i < 12; i++) {
					sum += this.personalInformation.cnp[i] * control[i];
				}
				sum = sum % 11;
				if (sum === 10) {
					sum = 1;
				}

				this.isValidCnp = sum === +this.personalInformation.cnp[12];
			}
		},
		validateEmail() {
			if (this.contactInformation.email.length === 0) {
				this.isValidEmail = true;
			}
			else {
				let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
				this.isValidEmail = re.test(this.contactInformation.email);
			}
		},
		validatePhoneNumber() {
			if (this.contactInformation.phoneNumber.length === 0) {
				this.isValidPhoneNumber = true;
			}
			else {
				let re = /^([+]|00)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
				this.isValidPhoneNumber = re.test(this.contactInformation.phoneNumber);
			}
		},
		validatePassword() {
			if (this.settings.password.length === 0) {
				this.isValidPassword = true;
			}
			else {
				if(this.settings.password.length < 8) {
					this.isValidPassword = false;
					return;
				}
				if(!this.settings.password.split('').some(c => c.match(/[A-Z]/))) {
					this.isValidPassword = false;
					return;
				}
				if(!this.settings.password.split('').some(c => c.match(/[a-z]/))) {
					this.isValidPassword = false;
					return;
				}
				if(!this.settings.password.split('').some(c => c.match(/[0-9]/))) {
					this.isValidPassword = false;
					return;
				}
				if(!this.settings.password.split('').some(c => c.match(/[~`!@#$%^&*()_+-={}|[\]\\:";'<>?,./'"]/))) {
					this.isValidPassword = false;
					return;
				}
				this.isValidPassword = true;
			}
		},
		validateConfirmPassword() {
			if (this.settings.confirmPassword.length === 0) {
				this.isValidConfirmPassword = true;
			}
			else {
				this.isValidConfirmPassword = this.settings.password === this.settings.confirmPassword;
			}
		},
		async onGetMrz(data) {
			console.log(data.detail);
			const file = new File([data.detail.image], "idCard.png");
			this.file = file;
			this.fileName = file.name;
			this.idCard = data.detail.image;
			await this.getMrz();
			this.showMrz = false;
		},
		onMrzError(err) {
			console.log('on mrz error', err);
		}
	}
};
</script>

<style lang="scss" scoped>
.logo {
	max-height: 75px;
	padding: 1rem;
	box-sizing: content-box;
}

.header {
	grid-column: 1;
	grid-row: 1;
}

.content {
	width: 100%;
	height: calc(100vh - 50px);
	display: grid;
	grid-template-rows: 75px 2fr 2fr 2fr 3fr;
	grid-template-columns: repeat(7, 1fr);
	gap: 2rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: #0f0f0fdd;
	color: #fafafa;
    backdrop-filter: grayscale(1) blur(5px);

	&.hidden {
		display: none;
	}

	> div {
		max-width: 800px;
	}
}

.hidden {
	display: none;
}

.container {
	grid-row: 2 / 4;
	grid-column: 2 / span 5;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 15px;
	justify-content: space-between;

	max-height: 65vh;

	.header {
		flex: 0;
		margin-bottom: 0.7rem;
	}
}

.left {
	grid-row: 1/-1;
	grid-column: 1 / span 4;
}

.right {
	grid-row: 1/-1;
	grid-column: 5/-1;
	background-color: #15416e;
	border-left: 1px solid #15416e;
}

.image {
	grid-row: 2 / span 2;
	grid-column: 5 / span 2;
}

.form {
	display: grid;

	&.columns-3 {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.columns-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	grid-template-rows: 70px 100px 1fr 0.7fr 80px;
	grid-gap: 0.5rem;

	max-height: 65vh;
	overflow-y: auto;

	.selectProduct {
		grid-column: 1;
		grid-row: 1;
	}

	.uploadIdCard {
		grid-column: 1;
		grid-row: 2;
	}

	.personalInformation,
	.contactInformation,
	.address,
	.delivery,
	.settings {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.personalInformation {
		grid-column: 2;
		grid-row: 1 / -2;

		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		.idCardNumberField {
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			width: 200px;

			.idCardSeries {
				flex: 1 0 55px;
			}

			.idCardNumber {
				flex: 3 0 calc(100% - 55px);
			}
		}
	}

	.contactInformation {
		grid-column: 1;
		grid-row: 3;
	}

	.address {
		grid-column: 3;
		grid-row: 1 / span 2;
	}

	.delivery {
		grid-column: 4;
		grid-row: 1 / -1;
	}

	.address-building {
		display: flex;
		gap: 0.5rem;
		width: 200px;

	}

	.settings {
		grid-column: 1;
		grid-row: 4;
	}

	.actions {
		grid-column: 1 / -1;
		grid-row: 5;
	}

	.mx-datepicker {
		.mx-input {
			border-radius: 5px;
		}
	}
}

.datepicker {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 17px;
	width: 200px;
	transition: all .2s ease-in-out;

	.mx-datepicker {
		position: relative;
		width: 100%;

		input:focus {
			-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
		}
	}

	span {
		position: absolute;
		top: 8px;
		left: .5rem;
		padding: 0;
		color: rgba(0, 0, 0, .4);
		font-size: 0.65rem;
	}

	&.hasValue span,
	.mx-datepicker:focus+span {
		transform: translate(-10px, -30px) !important;
		padding-left: .3rem;
		color: rgba(0, 0, 0, .6);
		font-size: .7rem;
	}
}

.upload-file {
	display: flex;
	align-items: center;
	font-size: 0.8rem;

	&:hover:not(.disabled) {
		-webkit-box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		-moz-box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		box-shadow: inset 0px 0px 55px 10px rgba(222, 222, 222, 1);
		cursor: pointer;
	}
}

.actions {
	display: flex;
	justify-content: space-between;
	padding: 0 1rem;
}

.end-screen {
	display: flex;
	justify-content: center;
	align-items: center;
}

.checkbox-click {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	.invisible {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1000;

		&:hover {
			cursor: pointer;
		}
	}
}

.prompt {
	max-height: 75vh;
	overflow-y: auto;
}

@media screen and (max-width: 800px) {
	.header {
		grid-column: 1 / -1;

		.logo {
			max-height: 50px;
			padding: 0.3rem 1rem;
		}
	}

	.content {
		grid-template-rows: 40px 2fr 2fr 2fr 3fr;
		gap: 0.2rem;
	}

	.container {
		grid-column: 1 / -1;
		max-height: 65vh;
	}

	.form {
		grid-column: 2 / 6;
		display: flex;
		flex-direction: column;
		max-height: 65vh;

		>div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.address-building {
			flex-direction: column;
		}

		.idCardNumberField {
			flex-direction: column;
			width: 100%;

			.idCardNumber {
				flex: 1;
			}

			.idCardSeries {
				flex: 1;
			}
		}

		.actions {
			margin-top: 1rem;
		}
	}

	.popover {
		height: calc(100vh - 50px);
		z-index: 1000000;
	}
}
</style>
