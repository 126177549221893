<template>
	<div style="height: 100%" :key="currentCulture">
		<div class="page">
			<vs-navbar
				v-model="active"
				text-color="rgba(44,62,80,.6)"
				active-text-color="rgba(44,62,80,1)"
				class="navbar"
				v-if="isUserLoggedIn"
			>
				<div slot="title" class="title">
					<vs-navbar-title><img class="logo" src="./assets/logo_2.png"/></vs-navbar-title>
					<vs-alert :active="showExpiryAlert" color="danger" @click="renewCertificate">{{
						i18n.getLocalised('expiringCertificate')
					}}</vs-alert>
				</div>
				<vs-navbar-item index="0">
					<router-link :to="{ name: 'Home' }">{{ i18n.getLocalised('menu.home') }}</router-link>
				</vs-navbar-item>
				<vs-navbar-item index="1">
					<router-link :to="{ name: 'Profile' }">{{ i18n.getLocalised('menu.profile') }}</router-link>
				</vs-navbar-item>
				<vs-button color="dark" type="flat" @click="logout()">
					Logout
				</vs-button>
			</vs-navbar>
			<div :class="isUserLoggedIn ? 'content' : 'content-full'">
				<transition name="fade" mode="out-in">
					<router-view></router-view>
				</transition>
			</div>
			<footer class="desktop-footer">
				<div class="custom-info">
					<span>Interesat de solutiile noastre?</span>
					<span><vs-icon icon="reply" size="16px"></vs-icon>office@alfasign.ro</span>
					<span><vs-icon icon="place" size="16px"></vs-icon>Calea Victoriei nr. 155, Etaj 9, Bucuresti, Romania</span>
					<span><vs-icon icon="phone" size="16px"></vs-icon>+40 (0) 371 901 902</span>
				</div>
				<vs-dropdown>
					<vs-button icon="expand_more" size="small">{{ currentCulture }}</vs-button>
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeCulture('ro-Ro')">
							<span>{{ getFlag('RO') }}</span>
						</vs-dropdown-item>
						<vs-dropdown-item @click="changeCulture('en-US')">
							<span>{{ getFlag('US') }}</span>
						</vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
			</footer>
			<footer class="mobile-footer" :class="{ 'show': showFooter }">
				<div class="action-button">
					<vs-icon size="50px" :icon="showFooter ? 'expand_more' : 'expand_less'" @click="toggleFooter"></vs-icon>
				</div>
				<div class="custom-info">
					<span>Interesat de solutiile noastre?</span>
					<span><vs-icon icon="reply" size="16px"></vs-icon>office@alfasign.ro</span>
					<span><vs-icon icon="place" size="16px"></vs-icon>Calea Victoriei nr. 155, Etaj 9, Bucuresti, Romania</span>
					<span><vs-icon icon="phone" size="16px"></vs-icon>+40 (0) 371 901 902</span>
				</div>
				<vs-dropdown>
					<vs-button icon="expand_more" size="small">{{ currentCulture }}</vs-button>
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeCulture('ro-Ro')">
							<span>{{ getFlag('RO') }}</span>
						</vs-dropdown-item>
						<vs-dropdown-item @click="changeCulture('en-US')">
							<span>{{ getFlag('US') }}</span>
						</vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
			</footer>
		</div>
	</div>
</template>

<script>
import { EventBus, State } from './services/events-service.js';
import { events } from './events.js';
import { UserService } from './services/user-service';
export default {
	data: function() {
		return {
			active: 0,
			isUserLoggedIn: false,
			currentCulture: '',
			showExpiryAlert: false,
			showFooter: false,
		};
	},
	async mounted() {
		EventBus.$on(events.userLoggedIn, async () => {
			await this.userLoggedIn();
		});
		EventBus.$on(events.userLoggedOut, () => {
			this.logout();
		});
	},
	async created() {
		const user = UserService.getUser();
		if (user) {
			this.isUserLoggedIn = true;
			await this.loadClientInformation();
		}

		const culture = this.i18n.getCurrentCulture();
		if (culture) this.currentCulture = this.getFlag(culture.split('-')[1]);
	},
	methods: {
		userLoggedIn: async function() {
			this.isUserLoggedIn = true;
			await this.loadClientInformation();
		},
		notifyError: function(message) {
			this.$vs.notify({
				title: 'Error',
				text: message,
				color: 'alert',
				position: 'bottom-center',
			});
		},
		loadClientInformation: async function() {
			const profile = (await UserService.getUserProfile()).data;
			State.profile = profile;
			console.log('Profile', profile);
			if (profile?.person?.clientCertificates) {
				console.log('Certs', profile.person.clientCertificates);
				const certificates = profile.person.clientCertificates
					.filter((f) => new Date(f.validTo) > new Date())
					.sort((a, b) => {
						return new Date(a.validTo) > new Date(b.validTo) ? -1 : 1;
					});
				console.log('FilteredCerts', certificates);
				if (certificates.length === 0) {
					State.userHasCertificates = false;
				} else if (
					new Date(certificates[0].validTo) > new Date() &&
					new Date(certificates[0].validTo) < new Date().setDate(new Date().getDate() + 7) &&
					certificates.length === 1
				) {
					this.showExpiryAlert = true;
					State.userHasCertificates = true;
				} else {
					State.userHasCertificates = true;
				}
			}

			console.log('State', State);

			EventBus.$emit(events.profileLoaded);
		},
		logout: async function() {
			try {
				await UserService.logout();
			} finally {
				this.isUserLoggedIn = false;
				this.$router.push({ name: 'Login' });
			}
		},
		getFlag(code) {
			const codePoints = code
				.toUpperCase()
				.split('')
				.map((x) => 127397 + x.charCodeAt());
			return String.fromCodePoint(...codePoints);
		},
		changeCulture(code) {
			this.i18n.setCulture(code);
			this.currentCulture = this.getFlag(code.split('-')[1]);
		},
		renewCertificate() {
			this.$router.push({ name: 'PurchaseCertificate' });
		},
		toggleFooter() {
			this.showFooter = !this.showFooter;
		},
	},
};
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/css?family=Hind+Siliguri:400,300,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Hind+Vadodara:400,300,500,600,700);

* {
	font-family: "Hind Siliguri", sans-serif;
	border-radius: 4px !important;
}

html,
body {
	font-size: 20px;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	display: flex;
	flex-direction: column;
}

.highlight {
	font-family: 'Merriweather', serif;
}

li {
	margin: 0 5px;
}

.navbar {
	padding: 15px 10px;
}

.router-link-active {
	font-size: 1.2rem;
}

.page {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-rows: min-content auto 50px;
	overflow: hidden;

	> .content {
		padding: 20px 20px;
		overflow-y: auto;
		grid-row: 2;
	}

	.content-full {
		grid-row: 2;
	}
}

.logo {
	max-height: 40px;
}

.scroll-content {
	height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

footer.desktop-footer {
	grid-row: 3;
	max-height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;
	background-color: #18BA60;

	.custom-info {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
		font-size: 14px;
	}
}

footer.mobile-footer {
	display: none;
}

.vs-navbar--header {
	flex: 1;

	.title {
		display: flex;
		flex-direction: row;
		width: 100%;
		> :nth-child(1) {
			flex: 0;
		}
		> :nth-child(2) {
			flex: 1 0 100%;
			max-width: 40%;
			margin: 0 auto;
			justify-self: center;
		}
	}
}

.vs-input--placeholder {
	font-size: 0.7rem !important;
	padding-left: 0.30rem !important;
}

.vs-alert:hover {
	cursor: pointer;
}

.vs-card--content {
	margin-bottom: 0 !important;
}

.vs-button {
	max-height: 40px;
}

.vs-checkbox--icon.material-icons {
	font-size: 18px !important;
}

@media screen and (max-width: 800px) {
	footer.desktop-footer {
		display: none;
	}
	footer.mobile-footer {
		padding: 10px;
		background-color: #18BA60;
		height: 350px;
		width: 100vw;
		position: absolute;
		bottom: -300px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 1.5rem;

		transition: all 0.3s ease-in-out;

		&.show {
			bottom: 0;
		}

		.custom-info {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1rem;
			font-size: 0.8rem;
		}
	}
}
</style>
