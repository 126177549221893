<template>
	<div class="content">
		<div class="left"></div>
		<div class="right"></div>
		<div class="panel login-container" v-if="confirmAccount">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('createCertificate') }}</h2>
			</div>
			<div>
				<p>{{ i18n.getLocalised('createCertificate.Message.Success') }}</p>
				<p>{{ i18n.getLocalised('createCertificate.Message.OTPRequired') }}</p>
			</div>
			<div class="login-form">
				<vs-input
					color="dark"
					label-placeholder="Password"
					type="password"
					v-model="password"
					:danger="passwordError"
					:danger-text="passwordErrorMessage"
				/>
				<vs-input
					color="dark"
					label-placeholder="Repeat Password"
					type="password"
					v-model="repeatPassword"
					:danger="repeatPasswordError"
					:danger-text="repeatPasswordErrorMessage"
				/>
			</div>
			<div class="errors" v-if="confirmError">
				<span class="error">{{ i18n.getLocalised('createCertificate.Error.SetPassword') }}</span>
			</div>
			<div class="actions">
				<vs-button color="primary" type="flat" @click="confirm()" :disabled="!allowConfirm">{{
					i18n.getLocalised('button.confirm')
				}}</vs-button>
			</div>
		</div>
		<div class="panel otp-container" v-if="otpScreen">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('login.otpTitle') }}</h2>
			</div>
			<div class="login-form">
				<vs-input color="dark" label-placeholder="SMS Code" v-model="otpCode" type="password" />
			</div>
			<div class="actions">
				<vs-button color="primary" type="border" @click="validateOtp()" :disabled="loading">{{
					i18n.getLocalised('button.confirm')
				}}</vs-button>
				<vs-button color="dark" type="flat" @click="confirm()" :disabled="!allowResend"
					>{{ i18n.getLocalised('button.resend') }} {{ allowResend ? '' : '(' + resendOtpTimer + ')' }}</vs-button
				>
			</div>
		</div>
	</div>
</template>
<script>
import i18nService from '../i18n/i18n.service';
import { UserService } from '../services/user-service.js';
export default {
	data: function() {
		return {
			password: '',
			repeatPassword: '',
			otpCode: '',
			sessionId: '',
			confirmAccount: false,
			otpScreen: false,
			laoding: false,
			confirmError: false,
			phoneValidation: {},
			passwordError: false,
			repeatPasswordError: false,
			passwordErrorMessage: '',
			repeatPasswordErrorMessage: '',
			allowConfirm: true,
			loading: false,
			resendOtpTimer: 0,
			allowResend: false,
		};
	},
	watch: {
		resendOtpTimer: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.resendOtpTimer--;
					}, 1000);
				} else {
					this.allowResend = true;
				}
			},
		},
	},
	methods: {
		confirm: async function() {
			this.repeatPasswordError = this.password !== this.repeatPassword;
			this.passwordError = this.password.length < 6;

			if (!this.repeatPasswordError && !this.passwordError) {
				this.allowConfirm = false;
				this.$vs.loading({ type: 'border' });
				try {
					const phoneResponse = await UserService.getOtpForCertificateRequest(this.sessionId);
					this.phoneValidation = {
						sessionId: phoneResponse.sessionId,
						sessionHash: phoneResponse.sessionHash,
					};
					this.confirmAccount = false;
					this.otpScreen = true;
					this.resendOtpTimer = 60;
					this.allowResend = false;
				} catch (err) {
					console.error(err);
				} finally {
					this.allowConfirm = true;
					this.$vs.loading.close();
				}
			}
		},
		validateOtp: async function() {
			this.loading = true;
			this.$vs.loading({ type: 'border', text: i18nService.getLocalised('createCertificate.Message.Loading') });
			try {
				await UserService.confirmCertificateRequest({
					otpCode: this.otpCode,
					sessionId: this.phoneValidation.sessionId,
					token: this.phoneValidation.sessionHash,
					password: this.password,
				});
				this.$router.push({ name: 'Login' });
			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
				this.$vs.loading.close();
			}
		},
	},
	mounted: async function() {
		this.$vs.loading({ type: 'border' });
		this.passwordErrorMessage = this.i18n.getLocalised('confirmAccount.Validation.PasswordTooShort');
		this.repeatPasswordErrorMessage = this.i18n.getLocalised('confirmAccount.Validation.PasswordsMustMatch');
		this.sessionId = this.$route.query.sid;
		try {
			await UserService.getCertificateRequest(this.sessionId);
			this.confirmAccount = true;
		} catch {
			this.$vs.notify({
				title: 'Error',
				text: 'Invalid session',
				color: 'danger',
				position: 'bottom-center',
			});
		} finally {
			this.$vs.loading.close();
		}
	},
};
</script>
<style lang="scss" scoped>
.content {
	width: 100%;
	height: calc(100vh - 50px);
	display: grid;
	grid-template-rows: 2fr 2fr 2fr 2fr;
	grid-template-columns: repeat(7, 1fr);
	gap: 2rem;
}

.left {
	grid-row: 1/-1;
	grid-column: 1 / span 4;
}
.right {
	grid-row: 1/-1;
	grid-column: 5/-1;
	background-color: #75b8c8;
	border-left: 1px solid #76b9d5;
}
.panel {
	grid-row: 2;
	grid-column: 2 / span 2;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 15px;
	background-color: #fff;
	// box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.5);
	justify-content: space-between;
	width: 80%;
}
.login-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	.vs-con-input-label {
		width: 100%;
	}
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
}

@media screen and (max-width: 800px) {
	.content {
		grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
		background: transparent;
	}
	.login-container,
	.otp-container {
		grid-column: 2/6;
		background: transparent;
		padding: 0;
	}
}

@media screen and (max-height: 700px) {
	* {
		font-size: 18px;
	}

	button {
		font-size: 16px;
	}
}
</style>
