import { env } from '../environment.js';
import { UserService } from './user-service';

const apiUrl = `${env.url}/audit/beacon`;

const sessionUUID = crypto.randomUUID();

function send(beaconData) {
	const user = UserService.getUser();
	const blob = new Blob(
		[
			JSON.stringify({
				...beaconData,
				sessionId: sessionUUID,
				userId: user?.id,
			}),
		],
		{ type: 'application/json' }
	);
	navigator.sendBeacon(apiUrl, blob);
}

export const BeaconService = {
	send,
};
