<template>
	<div class="content">
		<div class="profile">
			<vs-card>
				<div slot="header" class="header">
					<h6>{{ i18n.getLocalised('profile.title') }}</h6>
					<div class="actions">
						<vs-dropdown v-if="false">
							<vs-button color-text="rgb(0,0,0)" color="rgba(0,0,0, 0.3)" type="flat" icon="more_horiz"></vs-button>
							<vs-dropdown-menu>
								<vs-dropdown-item class="dropdown-item">
									{{ i18n.getLocalised('profile.menu.changePassword') }}
								</vs-dropdown-item>
								<vs-dropdown-item class="dropdown-item">
									{{ i18n.getLocalised('profile.menu.changeEmail') }}
								</vs-dropdown-item>
								<vs-dropdown-item class="dropdown-item">
									{{ i18n.getLocalised('profile.menu.changePhone') }}
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>
					</div>
				</div>
				<div class="card-content" v-if="user.person">
					<vs-input :label="i18n.getLocalised('profile.firstName')" disabled v-model="user.person.firstName" />
					<vs-input :label="i18n.getLocalised('profile.lastName')" disabled v-model="user.person.lastName" />
					<vs-input :label="i18n.getLocalised('profile.email')" disabled v-model="user.person.email" />
					<vs-input :label="i18n.getLocalised('profile.phoneNumber')" disabled v-model="user.person.phoneNumber" />
					<vs-input :label="i18n.getLocalised('profile.cnp')" disabled v-model="user.person.cnp" />
					<vs-input :label="i18n.getLocalised('profile.idCard')" disabled v-model="idCard" />
				</div>
			</vs-card>
		</div>
		<div class="orders">
			<vs-tabs color="success">
				<vs-tab :label="i18n.getLocalised('profile.orders.title')">
					<div class="tab-page">
						<vs-table :data="orders" :no-data-text="i18n.getLocalised('profile.noData')">
							<template slot="thead">
								<vs-th sort-key="id">
									{{ i18n.getLocalised('profile.orders.id') }}
								</vs-th>
								<vs-th sort-key="createdOn">
									{{ i18n.getLocalised('profile.orders.date') }}
								</vs-th>
								<vs-th sort-key="finalValue.price">
									{{ i18n.getLocalised('profile.orders.finalValue') }}
								</vs-th>
								<vs-th>
									{{ i18n.getLocalised('profile.orders.paymentMethod') }}
								</vs-th>
							</template>

							<template slot-scope="{ data }">
								<vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
									<vs-td :data="tr.id">
										{{ tr.id }}
									</vs-td>
									<vs-td :data="tr.createdOn">
										{{ tr.createdOn }}
									</vs-td>
									<vs-td :data="tr.finalValue"> {{ tr.finalValue.price }} {{ tr.finalValue.currencyCode }} </vs-td>
									<vs-td :data="tr.paymentMethod">
										{{ getPaymentMethod(tr.paymentMethod) }}
									</vs-td>
									<template slot="expand">
										<div class="documents-container">
											<vs-list>
												<vs-list-item
													v-for="(item, index) in tr.orderDocuments"
													:key="index"
													:title="item.documentName"
													:subtitle="getDocumentTypeName(item.documentType)"
												>
													<vs-button
														color="success"
														type="flat"
														icon="file_download"
														@click="downloadDocument(tr.id, item.id)"
													></vs-button>
												</vs-list-item>
											</vs-list>
										</div>
									</template>
								</vs-tr>
							</template>
						</vs-table>
					</div>
				</vs-tab>
				<vs-tab :label="i18n.getLocalised('profile.certificates.title')">
					<div class="tab-page">
						<vs-table :data="clientCertificates" :no-data-text="i18n.getLocalised('profile.noData')">
							<template slot="thead">
								<vs-th sort-key="index">
									{{ i18n.getLocalised('profile.certificates.index') }}
								</vs-th>
								<vs-th>
									{{ i18n.getLocalised('profile.certificates.serialNumber') }}
								</vs-th>
								<vs-th sort-key="validFrom">
									{{ i18n.getLocalised('profile.certificates.validFrom') }}
								</vs-th>
								<vs-th sort-key="validTo">
									{{ i18n.getLocalised('profile.certificates.validTo') }}
								</vs-th>
							</template>

							<template slot-scope="{ data }">
								<vs-tr :key="indextr" v-for="(tr, indextr) in data">
									<vs-td :data="tr.index">
										{{ tr.index + 1 }}
									</vs-td>
									<vs-td :data="tr.certificateSeries">
										{{ tr.certificateSeries }}
									</vs-td>
									<vs-td :data="tr.validFrom">
										{{ tr.validFrom }}
									</vs-td>
									<vs-td :data="tr.validTo">
										{{ tr.validTo }}
									</vs-td>
								</vs-tr>
							</template>
						</vs-table>
					</div>
				</vs-tab>
				<vs-tab :label="i18n.getLocalised('profile.signatures.title')">
					<div class="tab-page">
						<vs-table :data="signedFiles" :no-data-text="i18n.getLocalised('profile.noData')">
							<template slot="thead">
								<vs-th sort-key="index">
									{{ i18n.getLocalised('profile.signatures.index') }}
								</vs-th>
								<vs-th sort-key="fileName">
									{{ i18n.getLocalised('profile.signatures.fileName') }}
								</vs-th>
								<vs-th sort-key="createdOn">
									{{ i18n.getLocalised('profile.signatures.date') }}
								</vs-th>
							</template>
							<template slot-scope="{ data }">
								<vs-tr :key="indextr" v-for="(tr, indextr) in data">
									<vs-td :data="tr.index">
										{{ tr.index + 1 }}
									</vs-td>
									<vs-td :data="tr.fileName">
										{{ tr.fileName }}
									</vs-td>
									<vs-td :data="tr.createdOn">
										{{ tr.createdOn }}
									</vs-td>
								</vs-tr>
							</template>
						</vs-table>
					</div>
				</vs-tab>
				<vs-tab :label="i18n.getLocalised('profile.documents.title')">
					<div class="personal-documents">
						<div class="document-item" v-for="item in personalDocuments.filter((f) => f.documentType === 3)" :key="item.id">
							<vs-card actionable>
								<div slot="header">{{ getDocumentTypeName(item.documentType) }}</div>
								<div slot="media">
									<div style="max-width: 350px; max-height: 190px;">
										<img :src="item.document" />
									</div>
								</div>
								<div>
									<span>{{ item.documentName }}</span>
								</div>
								<div slot="footer">
									<vs-row vs-justify="flex-end">
										<vs-button type="gradient" icon="file_download" @click="downloadPersonalDocument(item)"></vs-button>
									</vs-row>
								</div>
							</vs-card>
						</div>
						<div class="document-item" v-for="item in personalDocuments.filter((f) => f.documentType === 5)" :key="item.id">
							<vs-card actionable>
								<div slot="header">{{ getDocumentTypeName(item.documentType) }}</div>
								<div slot="media">
									<div style="max-width: 350px; max-height: 190px;">
										<img :src="item.document" />
									</div>
								</div>
								<div>
									<span>{{ item.documentName }}</span>
								</div>
								<div slot="footer">
									<vs-row vs-justify="flex-end">
										<vs-button type="gradient" icon="file_download" @click="downloadPersonalDocument(item)"></vs-button>
									</vs-row>
								</div>
							</vs-card>
						</div>
					</div>
				</vs-tab>
			</vs-tabs>
		</div>
	</div>
</template>

<script>
import { saveAs } from 'file-saver';
import { UserService } from '../services/user-service';
export default {
	data: function() {
		return {
			user: {},
			clientCertificates: [],
			signedFiles: [],
			orders: [],
			selectedOrder: [],
			personalDocuments: [],
		};
	},
	computed: {
		idCard: function() {
			return this.user.person ? `${this.user.person.idCardSeries} ${this.user.person.idCardNumber}` : '';
		},
	},
	async mounted() {
		try {
			this.user = (await UserService.getUserProfile()).data;
			this.clientCertificates =
				this.user?.person?.clientCertificates.map((item, index) => {
					return {
						index,
						...item,
					};
				}) ?? [];
			this.orders =
				this.user.orders?.map((item, index) => {
					return {
						index,
						...item,
					};
				}) ?? [];
			this.signedFiles =
				(await UserService.getUserHistory()).data.map((item, index) => {
					return {
						index,
						...item,
					};
				}) ?? [];

			const documents = await UserService.getClientDocuments().data;

			this.personalDocuments =
				documents?.map(async (item) => {
					const document = (await UserService.getClientDocument(item.id)).data;
					const docSrc = URL.createObjectURL(document);
					return {
						...item,
						document: docSrc,
					};
				}) ?? [];

			console.log(this.personalDocuments);
		} catch (e) {
			console.error(e);
		}
	},
	methods: {
		getPaymentMethod(paymentMethod) {
			switch (paymentMethod) {
				case 1:
					return this.i18n.getLocalised('profile.orders.paymentMethods.online');
				case 2:
					return this.i18n.getLocalised('profile.orders.paymentMethods.offline');
			}
		},
		getDocumentTypeName(documentType) {
			switch (documentType) {
				case 0:
					return this.i18n.getLocalised('profile.orders.documentTypes.estimate');
				case 1:
					return this.i18n.getLocalised('profile.orders.documentTypes.invoice');
				case 3:
					return this.i18n.getLocalised('profile.orders.documentTypes.identityDocument');
				case 5:
					return this.i18n.getLocalised('profile.orders.documentTypes.biometricValidationDocument');
			}
		},
		viewDocument(document) {
			window.open(document.url, '_blank');
		},
		downloadPersonalDocument(document) {
			saveAs(document.document, document.documentName);
		},
		downloadDocument(orderId, documentId) {
			UserService.getOrderDocument(orderId, documentId).then((result) => {
				const fileData = result.data;
				const headerDisposition = result.headers['content-disposition'];
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(headerDisposition);
				let fileName = `${documentId}.pdf`;
				if (matches != null && matches[1]) {
					fileName = matches[1].replace(/['"]/g, '');
				}
				const blob = new Blob([fileData], { type: 'application/octet-stream' });
				saveAs(blob, fileName);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.con-vs-tabs {
	padding: 10px;

	.con-slot-tabs {
		height: calc(100% - 40px);
	}
}
.content {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	gap: 20px;

	.con-vs-card {
		height: 100%;
	}
	.profile,
	.orders {
		height: 100%;

		.header {
			display: flex;
			flex-direction: row;
			align-items: center;

			h6 {
				flex: 1;
			}

			.actions {
				flex: 0;
			}
		}
	}

	.profile {
		flex: 2;

		.card-content {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
	}

	.orders {
		flex: 5;
	}
}

.dropdown-item {
	padding: 0.25rem 0.5rem;
}

.vs-tabs {
	height: 100%;
	overflow-y: auto;
}
.documents-container {
	width: 100%;
	padding: 0 50px;

	.vs-list {
		max-width: 300px;
	}
}

.personal-documents {
	width: 100%;
	padding: 0 50px;
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	gap: 3rem;

	.document-item {
		height: 330px;
		width: 400px;

		.con-vs-card {
			height: 100%;
			display: flex;
			flex-direction: column;

			.vs-card-- {
				&* {
					flex: 0;
				}
			}

			.vs-card--content {
				flex: 1;
			}

			.vs-card--media {
				max-height: 200px;
				> div {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}
</style>
