<template>
    <div class="content">
        <div class="left"></div>
        <div class="right"></div>
        <div class="container">
            <div class="header">
                <p>{{ i18n.getLocalised('purchase.confirmAccount.end') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: calc(100vh - 50px);
    display: grid;
    grid-template-rows: 2fr 2fr 2fr 2fr;
    grid-template-columns: repeat(7, 1fr);
    gap: 2rem;
    
}

.container {
    grid-row: 2;
	grid-column: 2 / span 2;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 15px;
	background-color: #fff;
	justify-content: space-between;
    
    .header {
		flex: 0;
		margin-bottom: 0.7rem;
	}
}

.left {
    grid-row: 1/-1;
    grid-column: 1 / span 4;
}

.right {
    grid-row: 1/-1;
    grid-column: 5/-1;
    background-color: #75b8c8;
    border-left: 1px solid #76b9d5;
}

.image {
    grid-row: 2 / span 2;
    grid-column: 5 / span 2;
}

@media screen and (max-width: 800px) {
    .content {
        grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
    }
}
</style>
