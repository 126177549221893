import Vue from 'vue';
import { env } from '../environment.js';

const apiUrl = `${env.url}/companies`;

const getRegistrationForm = async function(formHash, companyId) {
	return await Vue.axios.get(`${apiUrl}/${companyId}/registration-forms/info?fh=${formHash}`);
};

const submitRegistrationForm = async function(formHash, companyId, form) {
	return await Vue.axios.post(`${apiUrl}/${companyId}/signatories/register?fh=${formHash}`, form);
};

const getMrzInformation = async function(companyId, formHash, document, fileName) {
	const formData = new FormData();
	formData.append('idDocument', document, fileName);
	return await Vue.axios.post(`${apiUrl}/${companyId}/register/mrz?fh=${formHash}`, formData);
};

const getAgreements = async function(companyId) {
	const terms = await Vue.axios.get(`${apiUrl}/${companyId}/terms-and-conditions`);
	const gdpr = await Vue.axios.get(`${apiUrl}/${companyId}/gdpr`);
	const privacy = await Vue.axios.get(`${apiUrl}/${companyId}/privacy-policy`);

	return {
		terms: terms.data,
		gdpr: gdpr.data,
		privacy: privacy.data,
	};
};

export const RegistrationFormService = {
	getRegistrationForm,
	submitRegistrationForm,
	getAgreements,
	getMrzInformation,
};
