<template>
	<div class="content">
		<div class="left"></div>
		<div class="right"></div>
		<div class="panel">
			<span v-if="checkAvailability">{{ i18n.getLocalised('videoSession.checkAvailability') }}</span>
			<span v-if="redirectingToSession">{{ i18n.getLocalised('videoSession.redirectToSession') }}</span>
			<span class="error-message" v-if="hasError">{{ i18n.getLocalised('videoSession.error.' + error) }}</span>
		</div>
	</div>
</template>

<script>
import { UserService } from '../services/user-service';
export default {
	data: function() {
		return {
			sessionId: '',
			token: '',
			clientId: 0,
			checkAvailability: false,
			redirectingToSession: false,
			error: '',
		};
	},
	computed: {
		hasError: function() {
			return !!this.error;
		},
	},
	async mounted() {
		this.token = this.$route.query.t;
		this.sessionId = this.$route.query.sid;
		this.clientId = this.$route.query.cid;
		if (!!this.token && !!this.sessionId && !!this.clientId) {
			const canStart = await this.getAvailability();
			if (canStart) {
				await this.createSession();
			}
		} else {
			this.error = 'INVALID_URL';
		}
	},
	methods: {
		getAvailability: async function() {
			this.checkAvailability = true;
			try {
				var response = await UserService.getVideoSessionAvailability(this.clientId, this.token, this.sessionId);
				if (response.canStart) {
					return true;
				} else {
					this.error = response.error;
				}
			} catch (err) {
				console.error(err);
				this.error = 'GENERIC_ERROR';
			} finally {
				this.checkAvailability = false;
			}
		},
		createSession: async function() {
			this.redirectingToSession = true;
			try {
				var response = await UserService.createVideoSession(this.clientId, this.token, this.sessionId);
				if (response.url) {
					window.location.assign(response.url);
				}
			} catch (err) {
				console.error(err);
				this.error = 'GENERIC_ERROR';
			} finally {
				this.redirectingToSession = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.content {
	width: 100%;
	height: calc(100vh - 50px);
	display: grid;
	grid-template-rows: 2fr 2fr 2fr 2fr;
	grid-template-columns: repeat(7, 1fr);
	gap: 2rem;
}

.left {
	grid-row: 1/-1;
	grid-column: 1 / span 4;
}
.right {
	grid-row: 1/-1;
	grid-column: 5/-1;
	background-color: #75b8c8;
	border-left: 1px solid #76b9d5;
}
.panel {
	grid-row: 2;
	grid-column: 2 / span 2;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 15px;
	background-color: #fff;
	// box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.5);
	justify-content: space-between;
}
</style>
