<template>
	<!-- <movable @move="onMove" @complete="endMove" :posTop="this.$props.data.y" :posLeft="this.$props.data.x" class="modal" v-if="!deleted"> -->
	<div class="modal" v-if="!deleted" :style="{ position: 'absolute', left: position.x, top: position.y }" @click.stop="">
		<div class="signature">
			<div class="modaltitle">{{ signature.title }}</div>
			<div>{{ signature.firstName }}</div>
			<div>{{ signature.lastName }}</div>
			<div>{{ signature.date.toLocaleString() }}</div>
		</div>
		<vs-button color="dark" type="flat" icon="delete" size="small" class="remove" @click.stop="remove"></vs-button>
	</div>
	<!-- </movable> -->
</template>

<script>
export default {
	props: ['data'],
	data: function() {
		return {
			id: 0,
			position: {
				x: 0,
				y: 0,
			},
			signature: {},
			deleted: false,
		};
	},
	created() {
		this.position.x = this.$props.data.x + 'px';
		this.position.y = this.$props.data.y + 'px';
		this.signature = this.$props.data.signature;
		this.id = this.$props.data.id;
	},
	methods: {
		onMove: function(e) {
			this.position.x = e.css.left;
			this.position.y = e.css.top;
		},
		endMove: function(e) {
			this.position.x = e.css.left;
			this.position.y = e.css.top;
		},
		remove: function() {
			this.$emit('onDelete');
			setTimeout(() => {
				this.deleted = true;
			}, 50);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	background: #acacac33;
	width: 180px;
	height: 80px;
	font-size: 0.7rem;
	display: flex;
	flex-direction: row;
	z-index: 100;
	> .signature {
		display: flex;
		flex-direction: column;

		flex: 1 1 100%;
	}
	> .remove {
		position: relative;
		right: 0px;
		top: 0px;
		border: 1px solid #acacac33;
	}
}
.modal:hover {
	border: 1px solid black;
}
</style>
