import Vue from 'vue';
import { env } from '../environment.js';
import { UserService } from './user-service';

const apiUrl = `${env.url}/documents`;

const requestUploadFile = (fileName, fileData, signatures, clientPin) => {
	const user = UserService.getUser();
	if (user) {
		const request = new FormData();
		request.append('FileData', fileData, fileName);
		request.append('Signatures', JSON.stringify(signatures));
		request.append('ClientPin', clientPin);

		return Vue.axios.post(`${apiUrl}`, request, {
			headers: {
				Authorization: `Bearer ${user.token}`,
			},
		});
	}
	Promise.reject('Invalid User');
};

const uploadFile = (fileName, fileData, settings, otpSession) => {
	const user = UserService.getUser();
	if (user) {
		const request = new FormData();
		request.append('FileData', fileData, fileName);
		request.append('Signatures', JSON.stringify({ pageSignatures: settings.signatures }));
		request.append('ClientPin', settings.clientPin);
		request.append('SessionId', otpSession.sessionId);
		request.append('OtpCode', otpSession.otpCode);
		request.append('RememberMe', otpSession.rememberMe);

		let headers = { Authorization: `Bearer ${user.token}` };
		if (settings.encryptFile) {
			headers = {
				...headers,
				'X-EncryptDocument': true,
				'X-EncryptionPassword': settings.encryptionPassword,
			};
		}

		return Vue.axios.put(`${apiUrl}`, request, {
			headers: headers,
			responseType: 'blob',
		});
	}
	Promise.reject('Invalid User');
};

export const DocumentsService = {
	requestUploadFile,
	uploadFile,
};
