import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';
import movable from 'v-movable';
import SmoothVuebar from 'smooth-vuebar';
import VueMarkdown from 'vue-markdown-v2';

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import i18nService from './i18n/i18n.service.js';
import { EventBus } from './services/events-service.js';
import { events } from './events.js';
import { BeaconService } from './services/beacon.service';

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['mrz-component'];
if (!(crypto.randomUUID instanceof Function)) {
	crypto.randomUUID = function uuidv4() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	};
}

i18nService.init('ro-Ro', 'en-US').then(() => {
	Vue.prototype.i18n = i18nService;
	Vue.prototype.$beacon = BeaconService;

	Vue.use(Vuesax);
	Vue.use(movable);
	Vue.use(SmoothVuebar);
	Vue.use(VueMarkdown);

	new Vue({
		router,
		store,
		render: (h) => h(App),
		components: {
			VueMarkdown,
		},
	}).$mount('#app');
});

Vue.axios.interceptors.response.use(
	(response) => response,
	(error) => {
		let errorInfo = {};
		try {
			errorInfo = {
				...error.toJSON(),
				data: error.response?.data,
				status: error.response?.status,
			};
		} catch {
			errorInfo = {
				data: error.response?.data,
			};
		}

		BeaconService.send({
			type: 2,
			message: error.message,
			error: error?.description,
			callStack: error?.stack,
			context: JSON.stringify({
				...errorInfo,
				location: 'Axios Interceptor',
			}),
		});

		if (errorInfo.status === 401) {
			EventBus.$emit(events.userLoggedOut);
		} else {
			return Promise.reject(error);
		}
	}
);
