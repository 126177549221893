<template>
	<div class="container">
		<header>{{ i18n.getLocalised('purchase.title') }}</header>
		<section class="purchase-steps">
			<section class="product-list">
				<div v-for="(item, idx) in availableProducts" :key="idx">
					<article
						class="product-item"
						:class="{ recommended: item.recommended, selected: selectedProduct && item.id === selectedProduct.id }"
						v-if="step === 1 || (step >= 2 && item.id === selectedProduct.id)"
					>
						<h2 class="product-name">{{ item.name }}</h2>
						<h1 class="product-price" v-if="item.showRenewal">
							<span class="product-price-old">{{ item.price.price }} {{ item.renewalPrice.currencyCode }}</span>
							<span class="product-price-new">{{ item.renewalPrice.price }} {{ item.renewalPrice.currencyCode }}</span>
						</h1>
						<h1 class="product-price" v-else>
							<span class="product-price-new">{{ item.price.price }} {{ item.renewalPrice.currencyCode }}</span>
						</h1>
						<p class="description">{{ item.description }}</p>
						<div class="actions">
							<vs-button type="gradient" color="success" @click="checkInvoiceAddressStep(item)" v-if="step === 1">{{
								i18n.getLocalised('purchase.button.continue')
							}}</vs-button>
						</div>
					</article>
				</div>
			</section>
			<section v-if="step === 2" class="product-step-info" ref="confirmId">
				<h3>{{ i18n.getLocalised('purchase.title.newIdCard') }}</h3>
				<div>
					<label for="fileSelector" ref="fileSelector">
						<vs-icon icon="upload_file" :size="'small'" color="success"></vs-icon
						>{{ i18n.getLocalised('profile.loadIdDocument') }}</label
					>
					<input type="file" id="fileSelector" accept="image/*" @change="uploadNewIdCard" style="display: none" />
				</div>
				<div v-if="mrzDataValid">
					<article class="personal-info">
						<div>
							<vs-input
								:label="i18n.getLocalised('profile.cnp')"
								v-model="mrzData.cnp"
								:danger="!mrzValidation.cnp"
								@change="validateMrz"
							/>
							<vs-input
								:label="i18n.getLocalised('profile.birthDate')"
								type="date"
								v-model="mrzData.birthDate"
								:danger="!mrzValidation.birthDate"
								@change="validateMrz"
							/>
						</div>
						<div>
							<vs-input
								:label="i18n.getLocalised('profile.firstName')"
								v-model="mrzData.firstName"
								:danger="!mrzValidation.firstName"
								@change="validateMrz"
							/>
							<vs-input
								:label="i18n.getLocalised('profile.lastName')"
								v-model="mrzData.lastName"
								:danger="!mrzValidation.lastName"
								@change="validateMrz"
							/>
						</div>
						<div>
							<vs-input
								:label="i18n.getLocalised('profile.idSeries')"
								v-model="mrzData.documentSeries"
								:danger="!mrzValidation.documentSeries"
								@change="validateMrz"
							/>
							<vs-input
								:label="i18n.getLocalised('profile.idNumber')"
								v-model="mrzData.documentNumber"
								:danger="!mrzValidation.documentNumber"
								@change="validateMrz"
							/>
						</div>
						<div>
							<vs-input
								:label="i18n.getLocalised('profile.idIssueDate')"
								type="date"
								v-model="mrzData.issueDate"
								:danger="!mrzValidation.issueDate"
								@change="validateMrz"
							/>
							<vs-input
								:label="i18n.getLocalised('profile.idExpiryDate')"
								type="date"
								v-model="mrzData.expiryDate"
								:danger="!mrzValidation.expiryDate"
								@change="validateMrz"
							/>
						</div>
					</article>
				</div>
				<div v-if="!requiresNewIdCard && !idDocument" class="confirm-id-card">
					<vs-checkbox color="success" v-model="confirmedValidId" icon="">{{
						i18n.getLocalised('purchase.confirmIdCard')
					}}</vs-checkbox>
				</div>
				<div class="confirm-id-card">
					<vs-checkbox color="success" v-model="allowBiometricValidation" icon="">{{
						i18n.getLocalised('purchase.confirmBiometricValidation')
					}}</vs-checkbox>
				</div>
				<div v-if="(!requiresNewIdCard && confirmedValidId) || mrzDataValid" class="step-actions">
					<vs-button type="gradient" color="success" @click="validateId()" :disabled="!mrzDataIsValid">
						{{ i18n.getLocalised('purchase.button.continue') }}
					</vs-button>
				</div>
			</section>
			<section v-if="step === 3" class="product-step-info">
				<h3>{{ i18n.getLocalised('purchase.title.invoiceAddress') }}</h3>
				<article class="invoice-address">
					<div>
						<vs-input
							:label="i18n.getLocalised('purchase.address.street')"
							v-model="invoiceAddress.street"
							:danger="!invoiceAddressValidation.street"
							@change="validateInvoiceAddress"
						/>
						<vs-input
							:label="i18n.getLocalised('purchase.address.streetNumber')"
							v-model="invoiceAddress.streetNumber"
							:danger="!invoiceAddressValidation.streetNumber"
							@change="validateInvoiceAddress"
						/>
					</div>
					<div>
						<vs-input :label="i18n.getLocalised('purchase.address.building')" v-model="invoiceAddress.building" />
						<vs-input :label="i18n.getLocalised('purchase.address.buildingNumber')" v-model="invoiceAddress.buildingNumber" />
						<vs-input :label="i18n.getLocalised('purchase.address.apartment')" v-model="invoiceAddress.apartment" />
					</div>
					<div>
						<vs-select
							autocomplete
							:label="i18n.getLocalised('purchase.address.county')"
							v-model="invoiceAddress.county"
							:danger="!invoiceAddressValidation.county"
							@change="validateInvoiceAddress"
						>
							<vs-select-item :value="item.nume" :text="item.nume" v-for="item in counties" :key="item.auto" />"
						</vs-select>
						<vs-input
							:label="i18n.getLocalised('purchase.address.city')"
							v-model="invoiceAddress.city"
							:danger="!invoiceAddressValidation.city"
							@change="validateInvoiceAddress"
						/>
					</div>
					<div>
						<vs-button type="gradient" color="success" @click="confirmInvoiceAddress()" :disabled="!invoiceAddressIsValid">{{
							i18n.getLocalised('purchase.button.continue')
						}}</vs-button>
					</div>
				</article>
			</section>
			<section v-if="step === 4" class="payment-method">
				<article>
					<h4>{{ i18n.getLocalised('purchase.title.paymentMethod') }}</h4>
					<div class="payment-method-options">
						<div
							class="payment-method-option"
							v-for="item in selectedProduct.allowedPaymentMethods"
							:key="item"
							@click="selectPaymentMethod(item)"
						>
							<div class="card" :class="{ 'selected-payment-method': selectedPaymentMethod === 1 }" v-if="item === 1">
								<vs-icon icon="payment" size="large" color="rgba(var(--vs-success), .8)"></vs-icon>
								<span>{{ i18n.getLocalised('purchase.paymentMethods.card') }}</span>
							</div>
							<div class="card" :class="{ 'selected-payment-method': selectedPaymentMethod === 2 }" v-if="item === 2">
								<vs-icon icon="article" size="large" color="rgba(var(--vs-success), .8)"></vs-icon>
								<span>{{ i18n.getLocalised('purchase.paymentMethods.paymentOrder') }}</span>
							</div>
							<div class="card" :class="{ 'selected-payment-method': selectedPaymentMethod === 3 }" v-if="item === 3">
								<vs-icon icon="account_balance" size="large" color="rgba(var(--vs-success), .8)"></vs-icon>
								<span>{{ i18n.getLocalised('purchase.paymentMethods.bankTransfer') }}</span>
							</div>
							<div class="card" :class="{ 'selected-payment-method': selectedPaymentMethod === 4 }" v-if="item === 4">
								<vs-icon icon="money" size="large" color="rgba(var(--vs-success), .8)"></vs-icon>
								<span>{{ i18n.getLocalised('purchase.paymentMethods.cash') }}</span>
							</div>
						</div>
					</div>
					<div>
						<vs-button
							type="gradient"
							color="success"
							@click="purchaseProduct(selectedProduct)"
							:disabled="!invoiceAddressIsValid"
							>{{ i18n.getLocalised('purchase.button.continue') }}</vs-button
						>
					</div>
				</article>
			</section>
			<section v-if="step === 5" class="summary">
				<h3>{{ i18n.getLocalised('purchase.title.summary') }}</h3>
				<article>
					<div class="order-summary">
						<div class="personal-info">
							<h4>{{ i18n.getLocalised('purchase.title.personalInfo') }}</h4>
							<div>
								<label>{{ i18n.getLocalised('profile.firstName') }}: {{ mrzData.firstName }}</label>
								<label>{{ i18n.getLocalised('profile.lastName') }}: {{ mrzData.lastName }}</label>
							</div>
							<div>
								<label>{{ i18n.getLocalised('profile.cnp') }}: {{ mrzData.cnp }}</label>
								<label>{{ i18n.getLocalised('profile.birthDate') }}: {{ mrzData.birthDate }}</label>
							</div>
							<div>
								<label>
									{{ i18n.getLocalised('profile.idCard') }}: {{ mrzData.documentSeries }}{{ mrzData.documentNumber }}
								</label>
							</div>
							<div>
								<label>
									{{ i18n.getLocalised('profile.idIssueDate') }}:
									{{ new Date(mrzData.issueDate).toLocaleDateString() }}
								</label>
								<label>
									{{ i18n.getLocalised('profile.idExpiryDate') }}:
									{{ new Date(mrzData.expiryDate).toLocaleDateString() }}
								</label>
							</div>
						</div>
						<div class="invoice-address">
							<h4>{{ i18n.getLocalised('purchase.title.invoiceAddress') }}</h4>
							<div>
								<label
									>{{ i18n.getLocalised('purchase.address.street') }}: {{ invoiceAddress.street }}
									{{ invoiceAddress.streetNumber }}</label
								>
								<label
									>{{ i18n.getLocalised('purchase.address.building') }}: {{ invoiceAddress.building }}
									{{ invoiceAddress.buildingNumber }}</label
								>
								<label>{{ i18n.getLocalised('purchase.address.apartment') }}: {{ invoiceAddress.apartment }}</label>
							</div>
							<div>
								<label
									>{{ i18n.getLocalised('purchase.address.city') }}: {{ invoiceAddress.city }},
									{{ invoiceAddress.county }}</label
								>
							</div>
						</div>
					</div>
					<div class="step-actions">
						<vs-button type="gradient" color="success" @click="confirmOrder()" :disabled="!mrzDataIsValid">{{
							i18n.getLocalised('purchase.button.continue')
						}}</vs-button>
					</div>
				</article>
			</section>
			<section v-if="step === 6" class="finish-session">
				<h3>
					{{ i18n.getLocalised('purchase.title.finishSession') }}
				</h3>
				<template v-if="selectedPaymentMethod === 1">
					<p>{{ i18n.getLocalised('purchase.endPurchase') }}</p>
					<vs-button type="line" color="success" target :href="paymentUrl">{{
						i18n.getLocalised('purchase.button.payment')
					}}</vs-button>
				</template>
				<template v-else>
					<p>{{ i18n.getLocalised('purchase.endPurchase.notOnlinePayment') }}</p>
				</template>
			</section>
		</section>
	</div>
</template>

<script>
import { ProductsService } from '../services/products.service.js';
import { UserService } from '../services/user-service.js';
import { OrdersService } from '../services/orders.service.js';
import { State } from '@/services/events-service.js';
import { counties } from '@/utils.js';
export default {
	data: function() {
		return {
			correlationId: '',
			availableProducts: [],
			selectedProduct: null,
			step: 1,
			invoiceAddress: {},
			invoiceAddressValidation: {},
			requiresNewIdCard: false,
			sessionId: null,
			orderId: null,
			counties: counties,
			loadingMrzData: false,
			idDocument: null,
			mrzDataValid: false,
			mrzData: {},
			mrzValidation: {},
			paymentUrl: '',
			confirmedValidId: false,
			selectedPaymentMethod: 0,
			allowBiometricValidation: true,
		};
	},
	async mounted() {
		this.correlationId = crypto.randomUUID();
		this.availableProducts = await ProductsService.getProductsForUser();
		console.log('Available products: ', this.availableProducts);
	},
	computed: {
		mrzDataIsValid() {
			return Object.keys(this.mrzValidation).every((key) => this.mrzValidation[key]);
		},
		invoiceAddressIsValid() {
			return Object.keys(this.invoiceAddressValidation).every((key) => this.invoiceAddressValidation[key]);
		},
	},
	methods: {
		async checkInvoiceAddressStep(product) {
			this.selectedProduct = product;
			if (!State?.profile?.person || new Date(State?.profile?.person.idCardExpiryDate) < new Date()) {
				this.requiresNewIdCard = true;
				this.step = 2;
			} else {
				this.requiresNewIdCard = false;
				this.step = 2;
			}
		},
		async uploadNewIdCard(e) {
			this.$vs.loading({
				container: this.$refs.confirmId,
				scale: 1,
			});
			this.idDocument = e.target.files[0];
			const response = (await UserService.getMrzInformation(this.idDocument, this.idDocument.name)).data;
			console.log('MRZ Data', response);

			if (response.valid_score >= 2) {
				this.mrzDataValid = true;
				const sexCode = response.optional_data.substr(0, 1);
				let birthDate = this.getDate(response.birth_date);
				if (sexCode === 5 || sexCode === 6) {
					birthDate = new Date(birthDate.setFullYear(birthDate.getFullYear() + 100));
				}
				let expiryDate = this.getDate(response.expiry_date);
				if (expiryDate.getFullYear() < 2000) {
					expiryDate = new Date(expiryDate.setFullYear(expiryDate.getFullYear() + 100));
				}
				const issueDate = new Date(new Date(expiryDate).setFullYear(expiryDate.getFullYear() - 10));
				this.mrzData = {
					firstName: response.name,
					lastName: response.surname,
					documentSeries: response.document_number ? response.document_number.substr(0, 2) : '',
					documentNumber: response.document_number ? response.document_number.slice(2) : '',
					birthDate: birthDate.toISOString().split('T')[0],
					expiryDate: expiryDate.toISOString().split('T')[0],
					issueDate: issueDate.toISOString().split('T')[0],
					cnp: response.optional_data.substring(0, 1) + response.birth_date + response.optional_data.substring(1),
				};
				this.validateMrz();
			}

			this.$vs.loading.close(this.$refs.confirmId);
		},
		validateMrz() {
			this.mrzValidation = {
				firstName: this.mrzData.firstName ? true : false,
				lastName: this.mrzData.lastName ? true : false,
				documentSeries: this.mrzData.documentSeries ? true : false,
				documentNumber: this.mrzData.documentNumber ? true : false,
				birthDate: this.mrzData.birthDate ? true : false,
				expiryDate: this.mrzData.expiryDate ? true : false,
				issueDate: this.mrzData.issueDate ? true : false,
				cnp: this.mrzData.cnp ? true : false,
			};
		},
		async validateId() {
			try {
				if (this.requiresNewIdCard && this.mrzDataValid) {
					await UserService.updatePersonalInformation(this.idDocument, this.idDocument.name, this.mrzData);
				} else {
					this.mrzData = {
						firstName: State?.profile?.person?.firstName,
						lastName: State?.profile?.person?.lastName,
						documentSeries: State?.profile?.person?.idCardSeries,
						documentNumber: State?.profile?.person?.idCardNumber,
						birthDate: State?.profile?.person?.birthDate,
						expiryDate: State?.profile?.person?.idCardExpiryDate,
						issueDate: State?.profile?.person?.idCardIssueDate,
						cnp: State?.profile?.person?.cnp,
					};
				}
				this.sendBeacon(3, 'validateId', 'Id Validated');
				this.step = 3;
				this.validateInvoiceAddress();
			} catch (e) {
				this.sendBeacon(2, 'validateId', null, e);
				this.$vs.notify({
					title: 'Error',
					text: e.message,
					color: 'danger',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
				});
			}
		},
		validateInvoiceAddress() {
			this.invoiceAddressValidation = {
				street: this.invoiceAddress.street ? true : false,
				streetNumber: this.invoiceAddress.streetNumber ? true : false,
				county: this.invoiceAddress.county ? true : false,
				city: this.invoiceAddress.city ? true : false,
			};
		},
		confirmInvoiceAddress() {
			this.step = 4;
			this.sendBeacon(3, 'confirmInvoiceAddress', 'Invoice Address Confirmed');
		},
		selectPaymentMethod(paymentMethod) {
			this.selectedPaymentMethod = paymentMethod;
			console.log('paymentMethod', this.selectedPaymentMethod);
		},
		async purchaseProduct(product) {
			try {
				this.$vs.loading({
					container: this.$refs.confirmId,
					scale: 1,
				});
				const result = await OrdersService.placeOrder(
					product,
					this.selectedPaymentMethod,
					this.allowBiometricValidation,
					this.invoiceAddress
				);
				this.sendBeacon(3, 'validateId', 'Order Placed');
				this.requiresNewIdCard = result.requiresNewIdCard;
				this.orderId = result.orderId;
				this.sessionId = result.sessionHash;
				console.log(result);
				this.step = 5;
			} catch (e) {
				this.sendBeacon(2, 'purchaseProduct', null, e);
				this.$vs.notify({
					title: 'Error',
					text: e.message,
					color: 'danger',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
				});
			} finally {
				this.$vs.loading.close(this.$refs.confirmId);
			}
		},
		async confirmOrder() {
			try {
				this.$vs.loading({
					container: this.$refs.confirmId,
					scale: 1,
				});
				const result = await OrdersService.confirmOrder(this.orderId, this.sessionId);
				this.sendBeacon(3, 'confirmOrder', 'Order Confirmed');
				this.step = 6;
				if (this.selectedPaymentMethod === 1) {
					this.paymentUrl = result.paymentUrl;
					window.open(result.paymentUrl, '_blank');
				}
			} catch (error) {
				this.sendBeacon(2, 'confirmOrder', null, error);
				this.$vs.notify({
					title: 'Error',
					text: error.message,
					color: 'danger',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
				});
			} finally {
				this.$vs.loading.close(this.$refs.confirmId);
			}
		},
		getDate(dateString) {
			return dateString ? new Date(dateString.substring(0, 2), dateString.substring(2, 4) - 1, dateString.substring(4)) : null;
		},
		sendBeacon(beaconType, location, message = null, e = null) {
			this.$beacon.send({
				correlationId: this.correlationId,
				type: beaconType,
				message: e?.message || message,
				error: e?.cause,
				callStack: e?.stack,
				context: JSON.stringify({
					...this.$data,
					location: location,
				}),
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	header {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 3rem;
	}

	.purchase-steps {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 75%;
		margin: 0 auto;

		.personal-info,
		.invoice-address {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			> div {
				display: flex;
				flex-direction: row;
				gap: 2rem;
			}
		}
	}
}

.product-step-info {
	flex: 1;
	h3 {
		margin-bottom: 1rem;
	}

	.confirm-id-card {
		margin: 2rem 0;
		font-size: 0.75rem;
		display: flex;
		align-self: flex-start;

		.vs-checkbox {
			width: 24px;
			height: 24px;
		}
	}

	.step-actions {
		margin-top: 2rem;
	}
}

.payment-method-options {
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin: 2rem 0;

	.card {
		height: 150px;
		width: 200px;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
		padding: 1rem;
		border-radius: 0.5rem;
		background-color: #fff;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&.selected-payment-method,
		&:hover {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
			transform: translateY(-4px);
			border-radius: 2rem;
		}
	}
}

.finish-session {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.product-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	> div {
		margin-right: 4rem;
		&:last-of-type {
			margin-right: 0;
		}
	}

	.product-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		max-width: 350px;
		gap: 2rem;
		padding: 1rem;
		border-radius: 0.5rem;
		background-color: #fff;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&:hover {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
			transform: translateY(-4px);
			border-radius: 2rem;
		}
		&.selected {
			margin-right: 4rem;
		}
		&.recommended {
			border-top: 15px solid rgb(var(--vs-success));
		}
		.product-name {
			font-size: 1.5rem;
			font-weight: bold;
			margin-bottom: 1rem;
		}
		.product-price {
			font-size: 1.5rem;
			font-weight: bold;
			margin-bottom: 1rem;
			display: flex;
			flex-direction: column;

			.product-price-old {
				color: rgb(var(--vs-danger));
				text-decoration: line-through;
			}
			.product-price-new {
				color: rgb(var(--vs-success));
			}
		}
		.description {
			font-size: 1.2rem;
			margin-bottom: 1rem;
		}
		.actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-top: 1rem;
		}
	}
}

.summary {
	display: flex;
	flex-direction: column;

	h3 {
		margin-bottom: 1rem;
	}

	> article {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.order-summary {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			> div {
				flex: 1 0 50%;
				&.invoice-address,
				&.personal-info {
					gap: 1rem;
				}

				> div {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 0.25rem;
				}
			}
		}
	}

	.step-actions {
		margin-top: 2rem;
	}
}
</style>
