let currentCulture = {};

const dictionary = [];

async function init(...cultures) {
	const loadingPromises = [];
	for (let i = 0; i < cultures.length; i++) {
		loadingPromises.push(import(`./${cultures[i]}.json`).then(({ default: data }) => data));
	}

	return await Promise.all(loadingPromises).then((result) => {
		for (let i = 0; i < cultures.length; i++) {
			if (result[i]) {
				dictionary.push({ culture: cultures[i], dictionary: result[i] });
			}
		}

		currentCulture = dictionary[0];
		if (!localStorage.getItem('culture')) {
			localStorage.setItem('culture', cultures[0]);
		}

		setCulture(localStorage.getItem('culture'));
		return Promise.resolve();
	});
}

function getCurrentCulture() {
	return localStorage.getItem('culture');
}

function setCulture(cultureCode) {
	currentCulture = dictionary.find((f) => f.culture === cultureCode);
	localStorage.setItem('culture', cultureCode);
}

function getLocalised(code, data) {
	let result = currentCulture.dictionary[code];
	if (result) {
		if (data) {
			for (const [key, value] in Object.entries(data)) {
				result = result.replaceAll(key, value);
			}
		}
		return result;
	}
	return code;
}

export default {
	init,
	setCulture,
	getLocalised,
	getCurrentCulture,
};
