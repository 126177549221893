<template>
	<div class="content">
		<div class="left"></div>
		<div class="right"></div>
		<div class="login-container" v-if="confirmAccount">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('confirmAccount') }}</h2>
			</div>
			<div>
				<p>{{ i18n.getLocalised('confirmAccount.Message.Success') }}</p>
				<p>{{ i18n.getLocalised('confirmAccount.Message.OTPRequired') }}</p>
			</div>
			<div class="login-form">
				<vs-input color="dark" label-placeholder="Password" type="password" v-model="password"
					:danger="passwordError" :danger-text="passwordErrorMessage" />
				<vs-input color="dark" label-placeholder="Repeat Password" type="password" v-model="repeatPassword"
					:danger="repeatPasswordError" :danger-text="repeatPasswordErrorMessage" />
			</div>
			<div class="errors" v-if="confirmError">
				<span class="error">{{ i18n.getLocalised('confirmAccount.Error.SetPassword') }}</span>
			</div>
			<div class="actions">
				<vs-button color="primary" type="flat" @click="confirm()" :disabled="!allowConfirm">{{
					i18n.getLocalised('button.confirm')
				}}</vs-button>
			</div>
		</div>
		<div class="otp-container" v-if="otpScreen">
			<div class="header">
				<h2 class="heading">{{ i18n.getLocalised('login.otpTitle') }}</h2>
			</div>
			<div class="login-form">
				<vs-input color="dark" label-placeholder="SMS Code" v-model="otpCode" type="password" />
			</div>
			<div class="actions">
				<vs-button color="primary" type="border" @click="validateOtp()" :disabled="loading">{{
					i18n.getLocalised('button.confirm')
				}}</vs-button>
				<vs-button color="dark" type="flat" @click="confirm()" :disabled="!allowResend">{{
					i18n.getLocalised('button.resend') }} {{ allowResend ? '' : '(' + resendOtpTimer + ')' }}</vs-button>
			</div>
		</div>
	</div>
</template>

<script>
import { UserService } from '../services/user-service.js';
export default {
	data: function () {
		return {
			password: '',
			repeatPassword: '',
			otpCode: '',
			sessionId: '',
			confirmAccount: false,
			otpScreen: false,
			laoding: false,
			confirmError: false,
			phoneValidation: {},
			passwordError: false,
			repeatPasswordError: false,
			passwordErrorMessage: '',
			repeatPasswordErrorMessage: '',
			allowConfirm: true,
			loading: false,
			resendOtpTimer: 0,
			allowResend: false,
			userFlow: '',
		};
	},
	watch: {
		resendOtpTimer: {
			handler(value) {
				if (value > 0) {
					setTimeout(() => {
						this.resendOtpTimer--;
					}, 1000);
				} else {
					this.allowResend = true;
				}
			},
		},
	},
	methods: {
		confirm: async function () {
			this.repeatPasswordError = this.password !== this.repeatPassword;
			this.passwordError = this.password.length < 6 || !RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[\W]).{6,20})/).test(this.password);

			if (!this.repeatPasswordError && !this.passwordError) {
				this.allowConfirm = false;
				try {
					const phoneResponse = await UserService.getConfirmPhone(this.sessionId);
					this.phoneValidation = {
						sessionId: phoneResponse.sessionId,
						sessionHash: phoneResponse.sessionHash,
					};
					this.confirmAccount = false;
					this.otpScreen = true;
					this.resendOtpTimer = 60;
					this.allowResend = false;
				} catch (err) {
					console.error(err);
				} finally {
					this.allowConfirm = true;
				}
			}
		},
		validateOtp: async function () {
			this.loading = true;
			this.$vs.loading({ type: 'border' });
			try {
				await UserService.confirmPhone({
					otpCode: this.otpCode,
					sessionId: this.phoneValidation.sessionId,
					token: this.phoneValidation.sessionHash,
					password: this.password,
					userFlow: this.userFlow,
				});
				if (this.userFlow === 'reset-password') {
					this.$router.push({ name: 'Login' });
				} else if (this.userFlow === 'purchase-certificate' || this.userFlow === 'register-signatory') {
					this.$router.push({ name: 'EndConfirmAccount' });
				} else if (this.userFlow === 'employee-onboarding') {
					location.href = 'https://manager.alfasign.ro';
				}

			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
				this.$vs.loading.close();
			}
		},
	},
	mounted: function() {
		this.$vs.loading({ type: 'border' });
		this.passwordErrorMessage = this.i18n.getLocalised('confirmAccount.Validation.PasswordTooShort');
		this.repeatPasswordErrorMessage = this.i18n.getLocalised('confirmAccount.Validation.PasswordsMustMatch');
		this.sessionId = this.$route.query.token;
		this.userFlow = this.$route.query.userFlow;

		if (
			this.userFlow !== 'reset-password' &&
			this.userFlow !== 'purchase-certificate' &&
			this.userFlow !== 'employee-onboarding' &&
			this.userFlow !== 'register-signatory'
		) {
			this.$router.push({ name: 'ProcessError' });
			return;
		}

		UserService.confirmEmail(this.sessionId)
			.then(async () => {
				this.confirmAccount = true;
			})
			.catch((err) => {
				this.$vs.notify({
					title: 'Error',
					text: err,
					color: 'danger',
					position: 'bottom-center',
				});
			})
			.finally(() => {
				this.$vs.loading.close();
			});
	},
};
</script>

<style lang="scss" scoped>
.content {
	width: 100%;
	height: calc(100vh - 50px);
	display: grid;
	grid-template-rows: 2fr 2fr 2fr 2fr;
	grid-template-columns: repeat(7, 1fr);
	gap: 2rem;
}

.left {
	grid-row: 1/-1;
	grid-column: 1 / span 4;
}

.right {
	grid-row: 1/-1;
	grid-column: 5/-1;
	background-color: #75b8c8;
	border-left: 1px solid #76b9d5;
}

.image {
	grid-row: 2 / span 2;
	grid-column: 5 / span 2;
}

.login-container,
.otp-container {
	grid-row: 2;
	grid-column: 2 / span 2;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 15px;
	background-color: #fff;
	// box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.5);
	justify-content: space-between;

	.header {
		flex: 0;
		margin-bottom: 0.7rem;
	}

	.login-form {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: center;

		.vs-con-input-label {
			width: 100%;
		}
	}

	.errors {
		flex: 0;
		padding: 0.5rem 0;
		font-size: 0.7rem;

		.error {
			color: #ff2200;
		}
	}

	.actions {
		flex: 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}

@media screen and (max-width: 800px) {
	.content {
		grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
	}

	.login-container,
	.otp-container {
		grid-column: 2/6;
	}
}
</style>
